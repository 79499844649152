import React from "react";

export default function HedgeBotToken(props) {
  return (
      <>
       
        {/* // <!-- Hedge Bot Token section-->  */}
        <section id="hedge-token" class="bg-black">
            <div class="container px-5">
                <div class="row gx-5 pb-4 align-items-center">
                    <div class="col-lg-12 order-lg-1 mb-5 mb-lg-0">
                        <div class="container-fluid px-2 text-white">
                            <h2 class="font-core text-center py-md-4 py-3">HedgeBot Token (HBT)</h2>
                            
                            <div class="row align-items-center pt-md-4 gx-5">
                                <div class="col-md-1"></div>
                                <div class="col-md-10">
                                    <div class="text-start">
                                      <p class="mb-0">Network:<span class="font-weight-normal">............................. Binance Smart Chain (BSC)</span></p>
                                      <p class="mb-0">Total Supply:<span class="font-weight-normal">.................... 1,000,000</span></p>
                                      <p class="mb-0">Circulating Supply:<span class="font-weight-normal">.... 1,000,000</span></p>
                                      <p>Symbol:<span class="font-weight-normal">................................ HBT</span></p>
                                      <p class="mb-0 mt-4">Token Address<span class="font-weight-normal"> (on BscScan)</span></p>
                                      <p><span class="font-weight-normal">0x6ff85fF5468eA3E324660E123e74EE1AE6d7f7F0</span></p>
                                      <a class="align-items-center btn btn-theme d-flex d-inline-flex form-control-sm justify-content-center lh-base mb-5 mt-3 px-4 rounded-pill" href="https://bscscan.com/token/0x6ff85ff5468ea3e324660e123e74ee1ae6d7f7f0" target="_blank" rel="noreferrer"><span class="text-center text-white">Open on BscScan</span></a>
                                      <p class="font-weight-normal mt-4">HedgeBot Token (HBT) has a very limited total supply. All HBTs are put in circulation with the initial liquidity being locked up for 100 years. </p>
                                      <p class="font-weight-normal">10 HBTs must be staked in order to participate in the HedgeBot Ecosystem.</p>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>

                            </div>
                            <div class="row align-items-center pt-md-5 gx-5">
                                <div class="col-md-1 mb-5"></div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature item--> */}
                                    <div class="text-start">
                                        <h3 class="font-alt py-3">LIMITED SUPPLY:  1,000,000 (One Million)</h3>
                                        <p class="text-white mb-0 lead">HedgeBot tokens will be in high demand due to the limited supply. The use case  of HBT is both on and off the HedgeBot platform. These tokens are anticipated to see a huge price increase as more investors transition from traditional or centralized finance to the world DEFI.</p>
                                    </div>
                                </div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature img--> */}
                                    <div class="text-left">  
                                        <img src="assets/img/limited-supply.png" class="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-1 mb-5"></div>
                            </div>
                            <div class="row align-items-center flex-md-row-reverse pt-md-4 gx-5">
                                <div class="col-md-1 mb-5"></div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature item--> */}
                                    <div class="text-md-end text-left">
                                        <h3 class="font-alt py-3">BUY & SELL FEES</h3>
                                        <p class="text-white mb-0 lead">All transactions have a 2% fee which is passed on to all HBT holders as rewards. To be eligible for the rewards, holders must hold 100 HBT tokens in their wallet. 
                                            <br/>
                                            <br/>
                                            Another 2% fee will be automatically added to liquidity pool on PancakeSwap.
                                            <br/>
                                            <br/>
                                            A 4% fee will be allocated to the HedgeBot treasury vault to act as safety net that will cover if anything wrong were to happen in the market.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature img--> */}
                                    <div class="text-left">  
                                        <img src="assets/img/buynsell.png" class="img-fluid"  alt="" />
                                    </div>
                                </div>
                                <div class="col-md-1 mb-5"></div>
                            </div>
                            <div class="row align-items-center pt-md-4 gx-5">
                                <div class="col-md-1 mb-5"></div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature item--> */}
                                    <div class="text-start">
                                        <h3 class="font-alt py-3">MAX WALLET: 1,000 HBT</h3>
                                        <p class="text-white mb-0 lead">No single wallet can hold more than 1,000 HBT at once. We've implanted this measure to minimize whales and bots from adversely manipulating the market. </p>
                                    </div>
                                </div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature img--> */}
                                    <div class="text-left">  
                                        <img src="assets/img/max-wallet.png" class="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-1 mb-5"></div>
                            </div>
                            <div class="row align-items-center flex-md-row-reverse pt-md-4 gx-5">
                                <div class="col-md-1 mb-5"></div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature item--> */}
                                    <div class="text-md-end text-left">
                                        <h3 class="font-alt py-3">MULTIPLE USE CASES</h3>
                                        <p class="text-white mb-0 lead">HBT will provide many uses cases as we grow the HedgeBot ecosystem.</p>
                                    </div>
                                </div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature img--> */}
                                    <div class="text-left">  
                                        <img src="assets/img/use-cases.png" class="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-1 mb-5"></div>
                            </div>
                            <div class="row align-items-center pt-md-4 gx-5">
                                <div class="col-md-1 mb-5"></div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature item--> */}
                                    <div class="text-start">
                                        <h3 class="font-alt py-3">SUBSTANIBLTY</h3>
                                        <p class="text-white mb-0 lead">The HedgeBot token was designed to last forever via the blockchain without any human interaction at any point after the contract was deployed the excitement with (HBT) is just beginning.</p>
                                    </div>
                                </div>
                                <div class="col-md-5 mb-5">
                                    {/* <!-- Feature img--> */}
                                    <div class="text-left">  
                                        <img src="assets/img/sustainability.png" class="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-1 mb-5"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 order-lg-1 my-5 mb-lg-0">
                        <div class="text-center">
                            <a class="d-flex align-items-center flex-column justify-content-center text-decoration-none py-3" href="/swap"><button class="btn-hbt btn btn-theme rounded-pill text-white mb-lg-0">Buy HBT</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
      </>
  );
}