import React from "react";
export default function Home(props) {
  return (
    <>
    <div class="modal popup" id="homePagePopup" tabindex="-1" aria-labelledby="homePagePopupLabel" aria-hidden="true">
        <div class="vertical-alignment-helper">
            <div class="modal-dialog vertical-align-center">
                <div class="modal-content bg-transparent">
                    <div class="bg-gray border-swag modal-body py-5 px-4 rounded-div">
                        <h4 class="mb-4 pt-3">Welcome to HedgeBot</h4>
                        <h5 class="font-weight-bold mb-4">HedgeBot is a decentralized finance “Membership Only” community that specializes on hedge fund-like investment strategies in order to produce above average returns for its members. </h5>

                        <h5 class="font-weight-bold mb-4">All active members are required to stake a minimum of 10 HBT to participate in HedgeBot strategies and its ecosystem.</h5>

                        <h5 class="font-weight-bold mb-5">Essentially, owning and staking HBT tokens acts as proof of membership and meets our compliance and regulatory requirements.</h5>
                        <div class="">
                            <a href="/swap" class="align-items-center bg-gradient-primary-to-secondary btn btn-theme d-inline-flex font-weight-700 justify-content-center mb-lg-0 rounded-pill text-white">Buy HBT</a>
                            <a href="/hbt-staking" class="ms-5 align-items-center bg-gradient-primary-to-secondary btn btn-theme d-inline-flex font-weight-700 justify-content-center mb-lg-0 rounded-pill text-white">Stake HBT</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <header class="masthead">
            <div class="container px-lg-5">
                <div class="row gx-lg-5">
                    <div class="col-lg-7 px-lg-5">
                        {/* <!--  Hedge Fund Heading & Paragraph --> */}
                        <div class="mb-5 mb-lg-0 px-lg-5 px-0 text-center text-white text-lg-start">
                            <h1 class="display-1 lh-1 mb-3 text-center">Decentralized<br/>Hedge Fund</h1>
                            <p class="lead pt-4 my-5">HedgeBot is the world's first sustainable decentralized hedge fund ecosystem providing up to 3.5 % weekly rewards to community members regardless economic condition, to provide a better financial  future for everyone. </p>
                            
                        </div>
                    </div>
                    <div class="col-lg-5">
                        {/* <!-- Feature video -->           */}
                        <button class="masthead-device-mockup text-decoration-none" id="vimeo" >
                            <svg fill='rgb(93, 101, 230)' id='ww-youtube-play' version='1.1' xmlns='http://www.w3.org/2000/svg' width='300' height='100%' viewBox='0 2 14 10'><path d='M10 7q0-0.289-0.234-0.422l-4-2.5q-0.242-0.156-0.508-0.016-0.258 0.141-0.258 0.438v5q0 0.297 0.258 0.438 0.125 0.062 0.242 0.062 0.156 0 0.266-0.078l4-2.5q0.234-0.133 0.234-0.422zM14 7q0 0.75-0.008 1.172t-0.066 1.066-0.176 1.152q-0.125 0.57-0.539 0.961t-0.969 0.453q-1.734 0.195-5.242 0.195t-5.242-0.195q-0.555-0.062-0.973-0.453t-0.543-0.961q-0.109-0.508-0.168-1.152t-0.066-1.066-0.008-1.172 0.008-1.172 0.066-1.066 0.176-1.152q0.125-0.57 0.539-0.961t0.969-0.453q1.734-0.195 5.242-0.195t5.242 0.195q0.555 0.062 0.973 0.453t0.543 0.961q0.109 0.508 0.168 1.152t0.066 1.066 0.008 1.172z'></path></svg>
                            <div class="d-block watch-video mt-3">Watch Video</div>
                        </button>
                    </div>
                </div>
                <div class="row gx-lg-5 pt-5">
                    <h5 class="text-center text-white font-weight-900">HedgeBot Ecosystem</h5>
                    <div class="px-3 py-4 d-flex flex-wrap align-items-center justify-content-center">
                        <a href="/contract" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3 mb-2"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">Trading Pool</button></a>
                        <a href="/trading-bot" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3 mb-2"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">Trading Bot</button></a>
                        <a href="/hedgebot-tokens" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3 mb-2"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">HedgeBot Tokens</button></a>
                        <a href="/swap" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3 mb-2"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">HBT Swap</button></a>
                        <a href="/hbt-staking" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3 mb-2"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">HBT Staking</button></a>
                    </div>
                </div>
            </div>
        </header>
        <div class="text-center bg-gradient-primary-to-secondary py-3 font-weight-normal ">
            <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-12">
                        <div class="font-3rem text-white">A Trustless And Autonomous Community</div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- App features section--> */}
        <section id="features">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-12 order-lg-1 mb-5 mb-lg-0">
                        <div class="container-fluid px-2 text-white">
                            <h2 class="font-core text-center py-md-5 py-3">HEDGEBOT CORE VALUES</h2>
                            <div class="row pt-md-12 gx-5 mt-5 pt-5">
                                <div class="col-md-4 mb-5">
                                    {/* <!-- Feature item--> */}
                                    <div class="text-md-end text-center">
                                        <img src="assets/img/autonomous.png" class="img-fluid" width="300" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-7 mb-5 text-start">
                                    {/* <!-- Feature item--> */}
                                    <h5 class="font-weight-700 mb-4">AUTONOMY & DECENTRALIZATION</h5>
                                    <p class="font-weight-normal">Decentralized Applications (dApps) built on blockchain technology offer several advantages, particularly in the realm of cryptocurrency. One of the primary benefits is their decentralized nature, which means that they operate on a distributed network without a central authority or intermediary.</p>
                                    <p class="font-weight-normal">HedgeBot, at its core, is what DEFI was truly designed to be. Unlike centralized financial institutions, once the smart contracts are deployed, they cannot be altered or changed.</p>
                                </div>
                                <div class="col-md-1 mb-5 text-start"></div>
                            </div>
                            <div class="row py-4">
                                <a href="/contract" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Get Started&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                            </div>
                            <div class="row pt-md-12 gx-5 mt-5 pt-5">
                                <div class="col-md-2 mb-5 text-start"></div>
                                <div class="col-md-8 bg-theme mb-5 text-start rounded-3 mt-4 p-5">
                                    <div class="bg-black rounded-div row p-5">
                                        <div class="col-md-4 mb-5 pt-5 pb-3">
                                            {/* <!-- Feature item--> */}
                                            <div class="text-md-end text-center">
                                                <img src="assets/img/decentralized.png" class="img-fluid" width="300" alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-8 mb-5 text-start pt-5 pb-3">
                                            <p class="font-weight-normal">With our dApps, HedgetBot is able to offer faster and cheaper transaction processing, as we eliminate the need for intermediaries and their associated fees.</p>
                                            <p class="font-weight-normal">Overall, with technologies like dApps & Web3, HedgeBot has the potential to revolutionize various financial sectors and provide global access to more monetary tools via digital systems and services</p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-md-2 mb-5 text-start"></div>
                            </div>
                            <div class="row pt-md-12 gx-5 mt-5 pt-5">
                                <div class="col-md-1 mb-5 text-start"></div>
                                <div class="col-md-7 mb-5 text-start mt-4">
                                    {/* <!-- Feature item--> */}
                                    <h5 class="font-weight-700 mb-4">SECURITY  & TRANSPARENCY</h5>
                                    <p class="font-weight-normal">In general, dApps are more secure, transparent, and resistant to censorship and hacking attempts. We've built our HedgeBot ecosystem on smart contracts to further promote the security of your funds.</p>
                                    <p class="font-weight-normal">We don't ask for personal or compromising information nor do we resell your data to third parties for any reason. We also provide users with greater control and ownership over their data and assets, enabling them to manage their finances and digital identities more securely and efficiently. </p>
                                </div>
                                <div class="col-md-4 mb-5">
                                    {/* <!-- Feature item--> */}
                                    <div class="text-md-start text-center">
                                        <img src="assets/img/secure.png" class="img-fluid" width="300" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="row py-4">
                                <a href="/contract" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Get Started&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <section id="features">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-12 order-lg-1 mb-5 mb-lg-0">
                        <div class="container-fluid px-2 text-white">
                            <h2 class="font-core text-center py-md-5 py-3">WHY WOULD YOU NEED A HEDGE FUND?</h2>
                            <div class="row pt-md-12 gx-5  pt-5">
                                <div class="col-md-4 mt-5 mb-5">
                                    <div class="text-center bg-theme rounded-3">
                                        <img src="assets/img/banner-vertical.webp" class="img-fluid rounded-div rel-img" alt="" />
                                    </div>
                                </div>
                                <div class="col-md-7 mb-5 mt-2 ps-5 text-start">
                                    <p class="font-weight-normal">Hedge funds offer several advantages for investors who are looking to diversify their portfolios and potentially earn higher returns.</p>
                                    <p class="font-weight-normal py-2">Some of the key advantages include:</p>
                                    <p class="font-weight-normal py-2">1. <span class="font-weight-700">Alternative Investment Strategies:</span> Hedge funds employ alternative investment strategies such as short-selling, derivatives, and leverage, which can generate higher returns than traditional investments.</p>
                                    <p class="font-weight-normal py-2">2. <span class="font-weight-700">Diversification:</span> Hedge funds invest in a wide range of asset classes, such as stocks, bonds, commodities, currencies, and real estate, providing investors with diversification and risk reduction benefits.</p>
                                    <p class="font-weight-normal py-2">3. <span class="font-weight-700">Professional Management:</span> Hedge funds are managed by experienced investment professionals who use sophisticated tools and techniques to analyze market trends and identify investment opportunities, potentially leading to higher returns.</p>
                                    <p class="font-weight-normal py-2">4. <span class="font-weight-700">Higher Returns:</span> Hedge funds have the potential to generate higher returns than traditional investments due to their alternative investment strategies and active management.</p>
                                    <p class="font-weight-normal py-2">5. <span class="font-weight-700">Flexibility:</span> Hedge funds offer investors greater flexibility in terms of investment horizons, investment minimums, and liquidity options.</p>
                                </div>
                                <div class="col-md-1 mb-5 text-start"></div>
                            </div>
                            <div class="row py-4">
                                <div class="col-md-1 mb-5 text-start"></div>
                                <div class="col-md-10 mb-5 text-start">
                                    <p class="font-weight-normal py-2">Traditionally, hedge funds are typically only available to accredited investors due to their higher minimum investment requirements and greater risks. </p>
                                    <p class="font-weight-normal py-2">At HedgeBot, we are changing that narrative by providing access to above-average returns while maintaining a good management of invested assets. Our members will be able to claim their earnings on a weekly basis.
                                    </p>
                                </div>
                                <div class="col-md-1 mb-5 text-start"></div>
                            </div>
                            <div class="row">
                                <a href="/contract" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0 font-weight-700">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Get Started&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </>
  );
}