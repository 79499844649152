import contract from "@truffle/contract";

export const loadContract = async (name, provider) => {
  const time = Math.floor(Date.now()/1000);
  const res = await fetch(`/contracts/${name}.json?t=`+time);
  const Artifact = await res.json();
  const _contract = contract(Artifact);
  _contract.setProvider(provider);
  const deployedContract = await _contract.deployed();
  return deployedContract;
};