import $ from 'jquery';

export function customAlert(message1,message2,description,href, status){
    if(status === "success"){
        $('#customAlert').find('.success').show();
        $('#customAlert').find('.error').hide();
    }else{
        $('#customAlert').find('.success').hide();
        $('#customAlert').find('.error').show();
    }
    $('#customAlert').find('.message1').text(message1);
    $('#customAlert').find('.message2').text(message2);
    $('#customAlert').find('.description').text(description);
    if(href){
        $('#customAlert').find('#closeModal').removeAttr('data-bs-dismiss');
        $('#customAlert').find('#closeModal').attr("href", href);
        $('#customAlert').find('#closeModal').text("Stake HBT");
    }else{
        $('#customAlert').find('#closeModal').text("OK");
        $('#customAlert').find('#closeModal').attr('data-bs-dismiss','modal');
        $('#customAlert').find('#closeModal').removeAttr("href");
    }
    $('#launchAlert').trigger('click');
}