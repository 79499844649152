import React from "react";

export default function Footer(props) {
  return (
        <footer class="bg-black text-center py-5">
            <div class="container px-5">
                <div class="text-white col-md-8 offset-md-2">
                    <p class="small h5 text-white px-5">We are not responsible for your wallet. If your wallet is compromised then we are not liable. Never give your seed phrase away to anyone.</p>
                    <div class="mt-5 h6 small"><small>HedgeBot © Copyright {new Date().getFullYear()}. All rights reserved</small></div>
                    
                </div>
            </div>
        </footer>
  );
}