import React from "react";
import $ from 'jquery';
import { customAlert } from "../components/customAlert";


export default function ReserveNow(props) {
    const reserveHandler = (event) => {
      event.preventDefault();
      $('#emailAddress').keyup(function(){
          $('#emailAddress').removeClass('error');
          $('#errorDiv').hide();
      });
      $('#walletAddress').keyup(function(){
          $('#walletAddress').removeClass('error');
          $('#errorDiv').hide();
      });
      $('#reserveButton').prop("disabled",true);
      $('#errorDiv').hide();
      $('#walletAddress').removeClass('error');
      $('#emailAddress').removeClass('error');
      var flag = true;
      let emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
      let walletRegex = new RegExp('((bc|tb)(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,87})|([13]|[mn2])[a-km-zA-HJ-NP-Z1-9]{25,39})');
      if(!$('#walletAddress').val()){
          $('#walletAddress').addClass('error');
          $('#walletAddress').focus();
          $('#errorDiv').show('slow');
          $('#errorDiv').text("Please enter a wallet address");
          setTimeout(function(){
              $('#errorDiv').hide('slow');
          },3000);
          flag  = false;
          $('#reserveButton').prop("disabled",false);
          return false;
      }
      if(!walletRegex.test($('#walletAddress').val())){
          $('#walletAddress').addClass('error');
          $('#walletAddress').focus();
          $('#errorDiv').show();
          $('#errorDiv').text("Please enter a valid wallet address");
          setTimeout(function(){
              $('#errorDiv').hide('slow');
          },3000);
          flag  = false;
          $('#reserveButton').prop("disabled",false);
          return false;
      }
      if(!$('#emailAddress').val()){
          $('#emailAddress').addClass('error');
          $('#emailAddress').focus();
          $('#errorDiv').text("Please enter a email address");
          $('#errorDiv').show();
          setTimeout(function(){
              $('#errorDiv').hide('slow');
          },3000);
          flag  = false;
          $('#reserveButton').prop("disabled",false);
          return false;
      }
      if(!emailRegex.test($('#emailAddress').val())){
          $('#emailAddress').addClass('error');
          $('#emailAddress').focus();
          $('#errorDiv').text("Please enter a valid email address");
          $('#errorDiv').show();
          
          setTimeout(function(){
              $('#errorDiv').hide('slow');
          },3000);
          $('#reserveButton').prop("disabled",false);
          flag  = false;
          return false;
      }
      
      if(flag){
          document.getElementById('loader').classList.remove('d-none');
          var subject = "Spot Reservation Request from HedgeBot";
          var body = "EMAIL: " + $('#emailAddress').val();
          body += "<br />";
          body += "Wallet Address: "+ $('#walletAddress').val();
          window.Email.send({
              SecureToken : "620a9f12-1014-4a01-a419-cd4e946b718f",
              To : 'hedgebotdefi@proton.me',
              From : "hedgebotdefi@proton.me",
              Subject : subject,
              Body : body
          }).then(
            message => {
              document.getElementById('loader').classList.add('d-none');
              $('#reserveButton').prop("disabled",false);
              $('#errorDiv').hide();
              $('#walletAddress').removeClass('error');
              $('#emailAddress').removeClass('error');
              $('#walletAddress').val('');
              $('#emailAddress').val('');
              if(message === 'OK'){
                customAlert("Success","","Your Spot Reservation request is successful","","success");
              }else{
                customAlert("Error", "","Your Spot Reservation is unsuccessful.Please try again later","", "error");
              }
            }
          );
      }
    }
  return (
    <>
      
      <section class="p-0">
        <div class="text-center bg-parrot py-2 font-weight-normal ">
            <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                        <h1 class="text-white font-weight-800">Get 50% OFF</h1>
                        <h5 class="text-white font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only 500 Subscribers available!&nbsp;&nbsp;&nbsp;</h5>
                        <a href="/reserve-now" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn bg-white rounded-1 text-black px-lg-4 py-2 mb-lg-0 font-weight-700 btn-reserve">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reserve Now&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center bg-white  font-weight-normal ">
              <div class="container px-5">
                  <div class="row gx-5 justify-content-center">
                      <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                         <img src="/assets/img/binance.webp" alt="Binance"  class="mx-2" />
                         <img src="/assets/img/coinbase.webp" alt="Coinbase Pro" class="mx-2 mb-2"   />
                         <img src="/assets/img/bitstamp.webp" alt="BitStamp" class="mx-2"  />
                         <img src="/assets/img/kucoin.webp" alt="Kucoin" class="mx-3"  />
                         <img src="/assets/img/kraken.webp" alt="Kraken" class="mx-2"  />
                         <img src="/assets/img/poloniex.webp" alt="Poloniex" class="mx-2"  />
                      </div>
                  </div>
              </div>
        </div>
        <div class="row">
          <div class="col-lg-6 offset-lg-3 py-lg-4">
            <div class="row py-lg-5">
              <div class="col-lg-4"><img src="assets/img/hedgebot-transparent-logo.webp" width="150" alt="" class="float-lg-end pt-lg-3 pe-lg-3" /></div>
              <div class="col-lg-8 p-lg-0 text-lg-start">
                <h1 class="font-weight-900 display-1 mb-1">HedgeBot</h1>
                <h1 class="fs-3 mb-1"><span class="font-weight-normal">AUTOMATED</span>&nbsp;<span class="font-weight-700">TRADING BOT</span></h1>
                <h5 class="small"><i>Get amazing returns with AI trading cryptocurrencies</i></h5>
              </div>
            </div>
          </div>
          <div class="col-lg-10 offset-lg-1 pt-lg-4 px-lg-5">
            <div class="row">
                <div class="col-lg-6 text-lg-start px-lg-0">
                  <h3 class="fs-2">Reserve Your Spot for the best Automated Trading Bot</h3>
                  <h1 class="pt-lg-4 font-weight-800 color-red mb-0"><strike>$97 USD / Month</strike></h1>
                  <h1 class="font-weight-800 display-4 color-green">$49 USD / Month</h1>
                </div>
                <div class="col-lg-6">
                  <form>
                    <div class="row mt-4">
                      <div class="col-lg-4 py-2 px-0 font-weight-normal">Wallet Address</div>
                      <div class="col-lg-7">
                        <input type="text" id="walletAddress" class="form-control input-submission" required />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-lg-4 py-2 px-0  font-weight-normal">Your e-mail</div>
                      <div class="col-lg-7">
                        <input type="email" id="emailAddress" class="form-control input-submission" required />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-lg-4 py-2 px-0  font-weight-normal"></div>
                      <div class="col-lg-7">
                        <div id="errorDiv" class="alert alert-danger" style={{display:'none'}}>Please fill all required fields!</div>

                        <div class="flex align-items-center flex-column justify-content-center text-decoration-none"><button id="reserveButton" onClick={reserveHandler} class="btn btn-reserve2 rounded-pill text-white px-lg-5 py-2 lh-lg mb-lg-0 font-weight-700 my-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Reservation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></div>                     
                      </div>

                    </div>
                  </form>
                  <div class="row mt-5">
                    <div class="col-lg-12 px-4">
                      <p class="font-weight-normal font-xs lh-base px-4 text-start mb-0"><i>FYI: We believe in privacy, so we do not KYC. Your email address is only used to send you updates regarding our trading bot and details about the release date.</i></p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section> 
      <div style={{background:'url(assets/img/limited-offer-bg.webp)',backgroundPosition:'left bottom',backgroundSize:'cover',height:"300px",opacity:"0.8"}}></div>
      <section class="p-0">
          <div class="text-center bg-white  font-weight-normal ">
              <div class="container px-5 py-3">
                  <div class="row gx-5 justify-content-center">
                      <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                         <img src="/assets/img/coinex.webp" alt="Coinex"  class="mx-3" />
                         <img src="/assets/img/bittrex.webp" alt="Bittrex" class="mx-3"   />
                         <img src="/assets/img/bybit.webp" alt="Bybit" class="mx-2"  />
                         <img src="/assets/img/binance-us.webp" alt="Binance.us" class="mx-3"  />
                         <img src="/assets/img/okex.webp" alt="Okex" class="mx-2"  />
                         <img src="/assets/img/huobi.webp" alt="Huobi" class="mx-2"  />
                      </div>
                  </div>
              </div>
          </div>
          <div class="text-center bg-parrot py-2 font-weight-normal ">
              <div class="container px-5">
                  <div class="row gx-5 justify-content-center">
                      <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                          <h1 class="text-white font-weight-800">Get 50% OFF</h1>
                          <h5 class="text-white font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only 500 Subscribers available!&nbsp;&nbsp;&nbsp;</h5>
                          <a href="/reserve-now"  class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn bg-white rounded-1 text-black px-lg-4 py-2 mb-lg-0 font-weight-700 btn-reserve">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reserve Now&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="p-0 bg-black pt-5">
          <div class="text-center font-weight-normal ">
              <div class="container px-5 py-5">
                <div class="row gx-5 justify-content-center">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-8 p-lg-0">
                      <h1 class="font-3rem font-weight-800 mb-5">FAQs</h1>
                      <p class="font-weight-700 h5 text-start pt-5">What is the difference between the automated “HedgeBot Trading Bot” and the “HedgeBot Trading Pool”?</p>
                      <p class="text-start mt-3 mb-4">When you decide to use our Trading Bot, you connect the trading exchange of your choice with our trading terminal. Your funds stay in your account at all times. You can withdraw them at any time.</p>
                      <p class="text-start mb-5">When you participate in HedgeBot’s Trading Pool, your funds are traded and managed by our expert traders and fund managers. You are charged a 1% fee for both deposits and withdrawals. Your initial capital investment is locked for 180 days. After that time, you can request a refund of your capital. Keep in mind that a 25% fee will be applied to your refund.</p>

                      <p class="font-weight-700 h5 text-start">If I cancel my current subscription can I subscribe again at the same price?</p>
                      <p class="text-start mt-3 mb-5">Yes, you will be able to join again at the price listed on the website when you decide to rejoin. Remember that subscriptions are all subject to availability. If subscriptions are closed, you can still join the waitlist.</p>
                      
                      <p class="font-weight-700 h5 text-start">What other automated trading bots will HedgeBot release?</p>
                      <p class="text-start mt-3 mb-5">We are currently only taking reservations for our Ethereum Trading Bot “OG-33”. If the community demand exceeds the initial 500 reservations, we will release our MATIC Trading Bot “OG-43” and potentially a Bitcoin Trading Bot “OG-53” to accommodate the demand.</p>
                      
                      <p class="font-weight-700 h5 text-start">Do I have to be a HedgeBot member to use the automated trading bot?</p>
                      <p class="text-start mt-3 mb-5">Yes, to access the trading bot you must buy and stake 10 HBT tokens to access our trading terminal and trading bots provided by HedgeBot.</p>
                      
                      <p class="font-weight-700 h5 text-start">How do I reserve my spot as one of the first 500 subscribers?</p>
                      <p class="text-start mt-3 mb-4">Simply provide your name and email address. You are not required to pay anything to join the waitlist. 72 hours before the public release of our Ethereum trading bot (OG-33), you will receive an email to complete your account setup. </p>
                      <p class="text-start mb-5">Only the first 500 who upgrade will access to the Ethereum trading bot (OG-33).</p> 

                      <p class="font-weight-700 h5 text-start">Got more questions? Looking for support?</p>
                      <p class="text-start mt-3 mb-5">We suggest you visit our knowledge base for more information about the HedgeBot ecosystem.</p>

                  </div>
                  <div class="col-lg-2"></div>
                </div>
              </div>
          </div>
      </section>
    </>
  );
}