import React from "react";

export default function Updates(props) {
  return (
      <>
        <section id="faq" class="bg-black">
            <div class="container px-5">
                <div class="row px-md-5 gx-5 align-items-center">
                    <iframe src="https://app.loopedin.io/hedgebot/updates" title="updates" height="900" width="100%" frameborder="0"></iframe>
                </div>
            </div>
        </section>
        
      </>
  );
}