/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { useState, useEffect } from "react";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { loadContract } from "../utils/load-contract";
import { customAlert } from "../components/customAlert";

export default function Contract(props) {
    const BigNumber = require('bignumber.js');
    const [amountToDeposit,setAmountToDeposit] = useState();
    const [account, setAccount] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [approved, setApproved] = useState(false);
    const [contractAddress, setContractAddress] = useState(null);
    const [userBalance, setUserBalance] = useState(null);
    const [totalDeposit, setTotalDeposit] = useState(null);
    const [totalDividends, setTotalDividends] = useState(null);
    const [totalWithdraw, setTotalWithdraw] = useState(null);
    const [contractBalance, setContractBalance] = useState(null);
    const [totalRefunded, setTotalRefunded] = useState(null);
    const [totalInvested, setTotalInvested] = useState(null);
    const [payoutAvailable, setPayoutAvailable] = useState(null);
    const [totalAvailableForRefund, setTotalAvailableForRefund] = useState(null);
    const [totalInvestedByUser, setTotalInvestedByUser] = useState(null);
    const [totalWithdrawnByUser, setTotalWithdrawnByUser] = useState(null);
    const [dailyPercentage,setDailyPercentage] = useState(null);
    const [timerStamp,setTimerStamp] = useState(false);
    const [timerStamp2,setTimerStamp2] = useState(false);
    let [timeRemaining, setTimeRemaining] = useState(null);
    let [timeRemaining2, setTimeRemaining2] = useState(null);
    const [totalUsers, setTotalUsers] = useState(null);
    const [reload, shouldReload] = useState(false);
    const [nextWithdraw, setNextWithdraw] = useState(false);
    const [nextRefund, setNextRefund] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [totalStaked, setTotalStaked] = useState(false);
    const [timerOn, setTimerOn] = useState(false);
    const [callPayout,setCallPayout] = useState(false);
    const [numdays,setNumDays] = useState(7);
    const [numdays2,setNumDays2] = useState(30);
    const [fees,setFees] = useState(1);
    const [refundFee,setRefundFee] = useState(25);
    const reloadEffect = (value) => {shouldReload(reload);shouldReload(!reload);setTimerOn(false);setTimerStamp(true);setTimerStamp2(true)};
    let setAccountListener = null;
    setAccountListener = (provider) => {
        provider.on("accountsChanged", (accounts) => (window.location.href = ''));
    };
    const bscTestChainId = 97;
    function containsOnlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }
    const amountChangeHandler = (event) => {
        setAmountToDeposit(event.target.value);
    };
    let formatting_options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }
    const [web3Api, setWeb3Api] = useState({
        web3: null,
        provider:null,
        contract:null,
        UsdcToken:null
    });
    let switchNetwork = (null);
    switchNetwork = async (chainId) => {
        const provider = await detectEthereumProvider();
        if(provider){
            const web3 = new Web3(provider);
            const currentChainId = await web3.eth.getChainId();
            if (currentChainId !== chainId){
                try {
                    setRequesting(true);
                    await provider.request({
                        method:'wallet_switchEthereumChain',
                        params: [{chainId: web3.utils.toHex(chainId)}]
                    });
                    setRequesting(false);
                }catch(err){
                    console.log(`error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`);
                    if (err.code === 4902){
                        addNetwork(bscTestNetwork);
                    }
                }
            }
        }
        
    }
    
    const bscTestNetwork = {
            chainId:Web3.utils.toHex(bscTestChainId),
            chainName: "BSC Testnet",
            nativeCurrency: {
            name: "BSC Testnet",
            symbol: "tBNB", // 2-6 characters long
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls:["https://explorer.binance.org/smart-testnet"]
    }

    const addNetwork = async(networkDetails) => {
        try{
            const ethereum = await detectEthereumProvider();
            await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    networkDetails
                ]
            });
        }catch(err){
            console.log(`error ocuured while adding new chain with chainId:${networkDetails.chainId}, err: ${err.message}`);
            //swal("Error",`Error ocuured while adding new chain with chainId:${networkDetails.chainId}`,'error');
        }
    }

    
     
    useEffect(() => {
        const loadProvider = async () => {
            
            try{
                const provider = await detectEthereumProvider();
                document.getElementById('loader').classList.remove('d-none');
                if (provider) {
                    setLoaded(true);
                    const contract = await loadContract("HedgeBotEcoSystem", provider);
                    const abi = await fetch(`/contracts/UsdcToken.json`);
                    const USDCTokenAbi = await abi.json();
                    let web3 = new Web3(provider);
                    let USDCTokenAddress = await contract.USD();
                    const UsdcToken = new web3.eth.Contract(USDCTokenAbi.abi, USDCTokenAddress,provider);
                    setContractAddress(contract.address);
                    setAccountListener(provider);
                    provider.request({ method: "eth_requestAccounts" });
                    setWeb3Api({
                        web3: web3,
                        provider,
                        contract,
                        UsdcToken
                    });
                    setLoaded(true);
                    document.getElementById('loader').classList.add('d-none');
                } else {
                    customAlert("Error", "Please install Metamask.", "", "", "error");
                    document.getElementById('loader').classList.add('d-none');
                    setLoaded(true);
                }
            }catch(e){
                console.log(e);
                document.getElementById('loader').classList.add('d-none');
                await switchNetwork(bscTestChainId);
                //swal("Error", "Please install MetaMask and connect to BSC network.", "error");
            }
        };
        !loaded && loadProvider();
    },[loaded,switchNetwork,setAccountListener]);
    
    const transferFund = async () => {
        const { contract,web3,UsdcToken } = web3Api;
        try{
            if(!containsOnlyNumbers(amountToDeposit)){
                customAlert("Error", "Please enter a valid amount.", "", "", "error");
            }else{
                
                if(totalStaked < 10){
                    customAlert("Please first stake a minimum of 10 HBT", "", "Staking HBT is required to participate in the HedgeBot Ecosystem", "/hbt-staking","error");
                    return;
                }
                document.getElementById('transferFund').setAttribute("disabled","disabled");
                if(!approved){
                    document.getElementById('loader').classList.remove('d-none');
                    try{
                        let maxApproval = new BigNumber(2).pow(256).minus(1).toFixed();
                        let hash = await UsdcToken.methods.approve(contract.address, maxApproval).send({from:account}).on('transactionHash',  (hash) => {
                            console.log(hash);
                        });
                        console.log(hash);
                    }catch(e){
                        console.log(e.message);
                        document.getElementById('loader').classList.add('d-none');
                        customAlert("Error", "Approval Cancelled", "", "", "error");

                        document.getElementById('transferFund').removeAttribute("disabled");
                        return false;
                    }
                    setApproved(true);
                    document.getElementById('loader').classList.add('d-none');
                    document.getElementById('transferFund').textContent = "Deposit";
                    document.getElementById('amountToDeposit').setAttribute("disabled","disabled");
                    customAlert(amountToDeposit+ " USDC", "Successfully Approved", "", "", "success");
                }else{
                    let amount =  web3.utils.toWei(amountToDeposit, "ether");
                    document.getElementById('loader').classList.remove('d-none');
                    try{
                        let hash = await contract.tradeDeposit(amount, { from: account });
                        console.log(hash);
                        document.getElementById('loader').classList.add('d-none');
                    }catch(e){
                        document.getElementById('loader').classList.add('d-none');
                        document.getElementById('amountToDeposit').removeAttribute("disabled");
                        document.getElementById('transferFund').removeAttribute("disabled");
                        if(e.message.includes("Stake")){
                            customAlert("Please first stake a minimum of 10 HBT", "", "Staking HBT is required to participate in the HedgeBot Ecosystem", "/hbt-staking","error");
                        }else{
                            customAlert("Error", "", e.message, "","error");
                        }
                        return false;
                    }
                    setApproved(false);
                    document.getElementById('amountToDeposit').removeAttribute("disabled");
                    document.getElementById('transferFund').textContent = "Approve";
                    customAlert(amountToDeposit+ " USDC", "Successfully Deposited", "", "", "success");
                    document.getElementById('amountToDeposit').value = '';
                    reloadEffect();
                }
                document.getElementById('transferFund').removeAttribute("disabled");
            }
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            document.getElementById('transferFund').removeAttribute("disabled");
            // let error = JSON.parse(e.message.replace("Internal JSON-RPC error.\n",""));
            // let message = error.message.charAt(0).toUpperCase() + error.message.slice(1);
            if(e.message.includes("Stake")){
                customAlert("Please first stake a minimum of 10 HBT", "", "Staking HBT is required to participate in the HedgeBot Ecosystem", "/hbt-staking","error");
            }else{
                customAlert("Error", "", e.message, "","error");
            }
            
        }
        
    };
    const rewardsWithdraw = async () => {
        const { contract } = web3Api;
        try{
            let nextTimeStamp = nextWithdraw;
            if(nextTimeStamp){
                let delta = (nextTimeStamp- Math.floor(Date.now() / 1000));
                if(delta <= 0 && parseFloat(payoutAvailable) > 0){
                    document.getElementById('rewardsWithdraw').setAttribute("disabled","disabled");
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.rewardsWithdraw({from:account});
                    document.getElementById('loader').classList.add('d-none');
                    document.getElementById('rewardsWithdraw').removeAttribute("disabled");
                    customAlert("Success", "Rewards withdrawn successfully.", "","","success");

                    reloadEffect();
                }else{
                    customAlert("Rewards are not yet available","","Rewards can only be withdrawn "+numdays+" days after previous withdrawal","","error");
                }
            }else{
                customAlert("Rewards are not yet available","","Rewards can only be withdrawn "+numdays+" days after previous withdrawal","","error");
            }
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            document.getElementById('rewardsWithdraw').removeAttribute("disabled");
            customAlert("Error", "",  e.message, "", "error");
        }
    };
    const exitTrading = async () => {
        const { contract } = web3Api;
        try{
            let nextTimeStamp = nextRefund ? nextRefund : ((Math.floor(Date.now() / 1000)) + (numdays2*24*3600));
            if(nextTimeStamp){
                let delta = (nextTimeStamp- (Math.floor(Date.now() / 1000)));
                if(delta <= 0){
                    document.getElementById('exitTrading').setAttribute("disabled","disabled");
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.exitTrading({from:account});
                    reloadEffect();
                    document.getElementById('loader').classList.add('d-none');
                    document.getElementById('exitTrading').removeAttribute("disabled");
                    customAlert("Success", "Refund Successful","","", "success");
                }else{
                    
                    customAlert("Your refund is not yet available","", "Refunds will be available "+numdays2+" days after your last deposit.","", "error");
                }
            }else{
                customAlert("Your refund is not yet available","", "Refunds will be available "+numdays2+" days after your last deposit.","", "error");
            }
            
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            document.getElementById('exitTrading').removeAttribute("disabled");
            customAlert("Error", "",  e.message, "", "error");
        }
        
    };
    useEffect(() => {
        const convertToSafeNumber  = (BN) => {
            let num = 0
            try{
                num = Math.round(web3Api.web3.utils.fromWei(BN, "ether") * 100) / 100;
                num = num.toFixed(2);
            }catch{
    
            }
            return num;
        }; 
        const getPayout = async () => {
            const { contract } = web3Api;
            if(contract){
                const payoutAvailable = await contract.computePayout(account);
                setPayoutAvailable(convertToSafeNumber(payoutAvailable) - 0);
                setCallPayout(false);
            }
        }
        
        callPayout && getPayout();
    },[web3Api, callPayout, account]);
    useEffect(()=>{
        let x = setInterval(function() {
            if(timerStamp){
                var deadline = timerStamp;
                var now = Math.floor(Date.now()/1000)*1000;
                var t = deadline - now;
                setCallPayout(true);
                var days = Math.floor(t / (1000 * 60 * 60 * 24));
                var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
                var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((t % (1000 * 60)) / 1000);
                let timeRemaining = '';
                if (t <= 0) {
                    //clearInterval(x);
                    timeRemaining = "Available Now";
                    setTimeRemaining(timeRemaining);
                }else{
                    timeRemaining = days+"d "+hours+"h "+minutes+"m "+seconds+"s";
                    setTimeRemaining(timeRemaining);
                }
            }
            
            if(!timerStamp){
                let timeRemaining = numdays+"d 0h 0m 0s";
                setTimeRemaining(timeRemaining);
            }
        }, 1000);
        return () => clearInterval(x);
    },[timerStamp,numdays])

    useEffect(()=>{
        let x = setInterval(function() {
            var now = Math.floor(Date.now()/1000)*1000;
            var deadline = timerStamp2;
            var t = deadline - now;
            var days = Math.floor(t / (1000 * 60 * 60 * 24));
            var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
            var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((t % (1000 * 60)) / 1000);
            let timeRemaining2 = '';
            setTotalAvailableForRefund(totalInvestedByUser - (totalInvestedByUser * refundFee/100));
            if (t <= 0) {
                //clearInterval(x);
                timeRemaining2 = "Refund Available";
                setTimeRemaining2(timeRemaining2);
                //refundFee
                //setCallRefund(true);
            }else{
                timeRemaining2 = days+"d "+hours+"h "+minutes+"m "+seconds+"s";
                setTimeRemaining2(timeRemaining2);
            }
            if(!timerStamp2){
                let timeRemaining2 = numdays2+"d 0h 0m 0s";
                setTimeRemaining2(timeRemaining2);
            }
        }, 1000);
        return () => clearInterval(x);
    },[timerStamp2,numdays2,refundFee,totalInvestedByUser])
    useEffect(() => {
        try{
            
            const getData = async () => {
                //console.log(Date.now());
                document.getElementById('loader').classList.remove('d-none');
                const convertToSafeNumber  = (BN) => {
                    let num = 0
                    try{
                        num = Math.round(web3Api.web3.utils.fromWei(BN, "ether") * 100) / 100;
                        num = num.toFixed(2);
                    }catch{
            
                    }
                    return num;
                }; 
                const {  contract , UsdcToken} = web3Api; 
                const accounts = await web3Api.web3.eth.getAccounts();
                const userAccountAddress = accounts.length;
                if(userAccountAddress){
                    setAccount(accounts[0]);
                    document.getElementById('headerBtn').innerText = "("+accounts[0].substring(0,4)+ "..." + accounts[0].slice(-4) + ")";
                    const userbalance = await UsdcToken.methods.balanceOf(accounts[0]).call();
                    setUserBalance(convertToSafeNumber(userbalance));

                    // Get User Payout
                    // Get USer Data
                    // Get Owner
                    const owner = await contract.getOwner();
                    const bot = await contract.botTrader();
                    console.log(bot);
                    if(owner.toLowerCase() === accounts[0].toLowerCase()){
                        setIsAdmin(true);
                    }
                    const userdata = await contract.investors(accounts[0]);
                    const nextWithdraw = await contract.nextWithdraw(accounts[0]);
                    const numdays = await contract.numDays();
                    const numdays2 = await contract.numDays2();
                    setNumDays(numdays.toNumber() - 0);
                    setNumDays2(numdays2.toNumber() - 0);
                    setNextWithdraw(nextWithdraw.toNumber())
                    const nextRefund = await contract.nextRefund(accounts[0]);
                    setNextRefund(nextRefund.toNumber());
                    if(userdata){
                        
                        const totalInvestedByUser = convertToSafeNumber(userdata.total_invested) - 0;
                        const lastDeposit = (userdata.lastDeposit.toNumber());
                        const totalStaked = (convertToSafeNumber(userdata.total_staked) - 0);
                        setTotalStaked(totalStaked);
                        setTotalInvestedByUser(totalInvestedByUser - 0);
                        
                        const totalWithdrawnByUser = (convertToSafeNumber(userdata.total_withdrawn) - 0) + (convertToSafeNumber(userdata.total_refunded)-0);
                        setTotalWithdrawnByUser(totalWithdrawnByUser);
                        const devFee = await contract.devFee();
                        const refundFee = await contract.refundFee();
                        const fee_divider = 1000;
                        setFees((devFee.toNumber()/fee_divider)*100);
                        setRefundFee((refundFee.toNumber()/fee_divider)*100)
                        if((lastDeposit-0) > 0){
                            setApproved(true);
                            document.getElementById('transferFund').textContent = "Deposit";
                        }else{
                            setApproved(false);
                            document.getElementById('transferFund').textContent = "Approve";
                        }
                        if(lastDeposit === 0){
                            setNextWithdraw(numdays+"d 0h 0m 0s"); // Not Available & No Deposit
                            setTimerStamp(false);
                        }else if((nextWithdraw.toNumber() > (Math.floor(Date.now()/1000)))){
                            let timerStamp = nextWithdraw.toNumber()*1000;
                            setTimerStamp(timerStamp);
                            setCallPayout(true);
                        }else if(nextWithdraw.toNumber() <= (Math.floor(Date.now()/1000))){
                            setTimerStamp(Math.floor(Date.now()/1000)*1000);
                            setCallPayout(true);
                        }
                        if(lastDeposit > 0){
                            let timerStamp2 = nextRefund.toNumber()*1000;
                            setTimerStamp2(timerStamp2);
                        }else{
                            setTimerStamp2(false);
                        }
                        // else{
                        //     setNextWithdraw(numdays+"d 0h 0m 0s"); // Not Available & No Deposit
                        //     setTimerStamp(false);
                        // }
                        setTimerOn(true);
                    }
                    
                }
                
                const totalDeposit = await contract.invested();
                setTotalDeposit(convertToSafeNumber(totalDeposit) - 0);
                // Get Contract Balance
                const contractBalance = await contract.getContractBalance();
                setContractBalance(convertToSafeNumber(contractBalance) - 0);
                // Get Total Refunds
                const totalRefunded = await contract.refunds();
                setTotalRefunded(convertToSafeNumber(totalRefunded)-0);
                const botFee = await contract.botFee();
                const partnerFee = await contract.partnerFee();
                const treasuryFee = await contract.treasuryFee();
                const totalPercentageForTrading = ( botFee.toNumber() + partnerFee.toNumber() + treasuryFee.toNumber() );
                // Get Total Invested
                const totalInvested = (convertToSafeNumber(totalDeposit) - 0)*totalPercentageForTrading/1000;
                setTotalInvested((totalInvested));
                // Get Tarif
                const tarifs = await contract.tarifs(0);
                setDailyPercentage((tarifs.percent.toNumber()/100));
                // Get Total Rewards
                const totalWithdraw = await contract.withdrawn();
                setTotalWithdraw(convertToSafeNumber(totalWithdraw) - 0);
                // Get Total Dividends
                const totalDividends = await contract.admin_dividends();;
                setTotalDividends(convertToSafeNumber(totalDividends) - 0);
                
                const totalUsers = await contract.nextMemberNo();
                setTotalUsers(totalUsers);
                document.getElementById('loader').classList.add('d-none');
            };
            web3Api.contract && !timerOn && getData();
            
        }catch(e){
            console.log(e);
            //swal("Error","There is issue in connecting to your wallet","error");
        }
    },[web3Api,timerOn,reload,timerStamp]);
  return (
    <>
    <header class="masthead">
            <div class="container px-lg-5">
                <div class="row gx-lg-5">
                    <div class="col-lg-7 px-lg-5">
                        {/* <!--  Hedge Fund Heading & Paragraph --> */}
                        <div class="mb-5 mb-lg-0 px-lg-5 px-0 text-center text-white text-lg-start">
                            <h1 class="display-1 lh-1 mb-3">Decentralized<br/>Trading Pool</h1>
                            <p class="lead my-5">HedgeBot trading pool is simple and easy to earn daily passive income from our trading bot and trading partners around the globe. We make no guarantee of a return you may earn using the Hedgebot platform and should only invest risk capital.</p>
                            
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <button class="masthead-device-mockup" id="vimeo2" >
                            <svg fill='rgb(93, 101, 230)' id='ww-youtube-play' version='1.1' xmlns='http://www.w3.org/2000/svg' width='300' height='100%' viewBox='0 2 14 10'><path d='M10 7q0-0.289-0.234-0.422l-4-2.5q-0.242-0.156-0.508-0.016-0.258 0.141-0.258 0.438v5q0 0.297 0.258 0.438 0.125 0.062 0.242 0.062 0.156 0 0.266-0.078l4-2.5q0.234-0.133 0.234-0.422zM14 7q0 0.75-0.008 1.172t-0.066 1.066-0.176 1.152q-0.125 0.57-0.539 0.961t-0.969 0.453q-1.734 0.195-5.242 0.195t-5.242-0.195q-0.555-0.062-0.973-0.453t-0.543-0.961q-0.109-0.508-0.168-1.152t-0.066-1.066-0.008-1.172 0.008-1.172 0.066-1.066 0.176-1.152q0.125-0.57 0.539-0.961t0.969-0.453q1.734-0.195 5.242-0.195t5.242 0.195q0.555 0.062 0.973 0.453t0.543 0.961q0.109 0.508 0.168 1.152t0.066 1.066 0.008 1.172z'></path></svg>
                            <div class="d-block watch-video mt-3">Watch Video</div>
                        </button>
                    </div>
                </div>
            </div>
    </header>
    <section id="features" class="bg-black bg-contract py-0">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-12 order-lg-1 mb-5 mb-lg-0">
                        <div class="container-fluid px-2 text-white">
                            <h2 class="font-feature text-center">FEATURES</h2>
                            <h2 class="font-feature text-center">UP TO <span class="font-weight-normal"><span id="rewardPercentage">3.5</span>% Weekly Rewards</span></h2>
                        </div>
                    </div>
                    <div class="col-lg-11 offset-lg-1 order-lg-2 my-5">
                        <div class="row my-3">
                            <div class="col-lg-5 offset-lg-1">
                                <h5 class="text-start text-white">No Minimum Deposit</h5>
                            </div>
                            <div class="col-lg-6">
                                <h5 class="text-start text-white">Claim Rewards Every {numdays} Days</h5>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-lg-5 offset-lg-1">
                                <h5 class="text-start text-white">Deposit Fee: {fees}%</h5>
                            </div>
                            <div class="col-lg-6">
                                <h5 class="text-start text-white">Withdraw Capital Anytime After {numdays2} Days</h5>
                            </div>
                        </div>
                        <div class="row my-3">
                            <div class="col-lg-5 offset-lg-1">
                                <h5 class="text-start text-white">Withdrawal Fee: {fees}%</h5>
                            </div>
                            <div class="col-lg-6">
                                <h5 class="text-start text-white">Capital Withdraw Fee: {refundFee}%</h5>
                            </div>
                        </div>
                        
                    </div>

                    <div class="col-lg-10 offset-lg-1 order-lg-3 mb-0 mb-lg-0">
                        <div class="container-fluid px-2 text-white">
                            <div class="row pt-md-4 gx-1">
                                <div class="col-md-4">
                                    <div class="text-center">
                                        <h4 class="bg-theme rounded-top py-2 font-weight-normal mb-0">Total Deposit</h4>
                                        <h2 class="font-alt font-weight-900 py-2 bg-value">${totalDeposit ?  (totalDeposit).toLocaleString(undefined,formatting_options) : "0.00"}<br/>USDC</h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-center">
                                        <h4 class="bg-theme rounded-top py-2 font-weight-normal mb-0">Contract Balance</h4>
                                        <h2 class="font-alt font-weight-900 py-2 bg-value">${contractBalance ?  (contractBalance).toLocaleString(undefined,formatting_options) : "0.00"}<br/>USDC</h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-center">
                                        <h4 class="bg-theme rounded-top py-2 font-weight-normal mb-0">Total Capital Withdrawn</h4>
                                        <h2 class="font-alt font-weight-900 py-2 bg-value">${totalRefunded ?  (totalRefunded).toLocaleString(undefined,formatting_options) : "0.00"}<br/>USDC</h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-center">
                                        <h4 class="bg-theme rounded-top py-2 font-weight-normal mb-0">Used For Trading</h4>
                                        <h2 class="font-alt font-weight-900 py-2 bg-value">${totalInvested ?  (totalInvested).toLocaleString(undefined,formatting_options) : "0.00"}<br/>USDC</h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-center">
                                        <h4 class="bg-theme rounded-top py-2 font-weight-normal mb-0">Contract Dividends</h4>
                                        <h2 class="font-alt font-weight-900 py-2 bg-value">${totalDividends ?  (totalDividends).toLocaleString(undefined,formatting_options) : "0.00"}<br/>USDC</h2>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-center">
                                        <h4 class="bg-theme rounded-top py-2 font-weight-normal mb-0">Claimed Rewards</h4>
                                        <h2 class="font-alt font-weight-900 py-2 bg-value">${totalWithdraw ?  (totalWithdraw).toLocaleString(undefined,formatting_options) : "0.00"}<br/>USDC</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-md-4 gx-5">
                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col-md-6 pe-md-0 mb-3">
                                            <div class="text-start bg-value rounded-3 px-3 py-4">
                                                <h2 class="font-alt font-weight-900 py-2">Deposit</h2>
                                                <p class="font-small text-center"><b>Your Wallet Address&nbsp;:&nbsp;</b><span>{account ? "("+account.substring(0,5)+ "..." + account.slice(-5) + ")" : "Not Connected"}</span></p>
                                                <input type="text" class="m-0 m-auto w-100 text-center" id="amountToDeposit" name="message" onChange={amountChangeHandler} placeholder="No Minimum" />
                                                <div class="d-block text-center py-2 px-3"><button onClick={transferFund} id="transferFund" class="btn btn-theme rounded-pill text-white px-4 py-2 mb-lg-0">Approve</button></div>
                                                <p class="font-small text-center pt-2">{fees}% deposit fee will be deducted from all deposits.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pe-md-0 mb-3">
                                            <div class="text-start bg-value rounded-3 px-3 py-4">
                                                <h2 class="font-alt font-weight-900 py-2">Rewards</h2>
                                                <h4 class="text-center font-value font-weight-bold">${payoutAvailable ?  (payoutAvailable).toLocaleString(undefined,formatting_options) : "0.00"}&nbsp;USDC</h4>
                                                <p class="text-center my-2">{timeRemaining ?  (timeRemaining) : numdays+"d 0h 0m"}</p>
                                                <div class="d-block text-center py-2 px-3"><button onClick={rewardsWithdraw} id="rewardsWithdraw" class="btn btn-theme rounded-pill text-white px-2 py-2 mb-lg-0">Claim Rewards</button></div>
                                                <p class="font-small text-center pt-2">{fees}% withdrawal fee will be deducted from all claims.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pe-md-0 mb-3">
                                            <div class="text-start bg-value rounded-3 px-3 py-4">
                                                <h2 class="font-alt font-weight-900 py-2">Capital Withdraw</h2>
                                                <h4 class="text-center font-value font-weight-bold">${totalAvailableForRefund ?  (totalAvailableForRefund).toLocaleString(undefined,formatting_options) : "0.00"}&nbsp;USDC</h4>
                                                <p class="text-center my-2">{timeRemaining2 ?  (timeRemaining2) : numdays+"d 0h 0m"}</p>
                                                <div class="d-block text-center py-2 px-3"><button onClick={exitTrading} id="exitTrading" class="btn btn-theme rounded-pill text-white px-2 py-2 mb-lg-0">Claim Refund</button></div>
                                                <p class="font-small text-center">Capital Withdraw is available {numdays2} days after your last deposit.  Note: {refundFee}% fee is applied</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pe-md-0 mb-3">
                                            <div class="text-start bg-value rounded-3 px-3 py-4">
                                                <h2 class="font-alt font-weight-900 pt-2 pb-4">Your Statistics</h2>
                                                <div class="row py-1">
                                                </div>
                                                <div class="row py-2">
                                                    <div class="col-6"><p class="m-0">Wallet Balance</p></div>
                                                    <div class="col-6 text-right"><p class="m-0 text-end">${userBalance ?  parseFloat((userBalance).toLocaleString(undefined,formatting_options)).toFixed(2) : "0.00"}<span class="font-weight-normal">&nbsp;&nbsp;&nbsp;USDC</span></p></div>
                                                </div>
                                                <div class="row py-2">
                                                    <div class="col-6"><p class="m-0">Deposited Amount</p></div>
                                                    <div class="col-6"><p class="m-0 text-end">${totalInvestedByUser ?  (totalInvestedByUser).toLocaleString(undefined,formatting_options) : "0.00"}<span class="font-weight-normal">&nbsp;&nbsp;&nbsp;USDC</span></p></div>
                                                </div>
                                                <div class="row py-2">
                                                    <div class="col-6"><p class="m-0">Total Withdrawn</p></div>
                                                    <div class="col-6"><p class="m-0 text-end">${totalWithdrawnByUser ?  (totalWithdrawnByUser).toLocaleString(undefined,formatting_options) : "0.00"}<span class="font-weight-normal">&nbsp;&nbsp;&nbsp;USDC</span></p></div>
                                                </div>
                                                <div class="row py-3">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 ps-0">
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <div class="text-center bg-value rounded-3 px-3 py-2">
                                                <h2 class="py-2 font-alt font-weight-normal mb-0">WEEKLY REWARD</h2>
                                                <h2 class="font-alt font-weight-900">{dailyPercentage}%</h2>
                                            </div>
                                        </div>  
                                        <div class="col-md-12 mb-3">
                                            <div class="text-center bg-value px-3 rounded-3 py-2">
                                                <h2 class="py-2 font-alt font-weight-normal mb-0">TOTAL USERS</h2>
                                                <h2 class="font-alt font-weight-900">{totalUsers ?  (totalUsers).toLocaleString(undefined,formatting_options) : "0"}</h2>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <div class="text-center bg-value rounded-3 px-3 py-2">
                                                <h2 class="py-2 font-alt font-weight-normal mb-0">DEPOSIT FEE</h2>
                                                <h2 class="font-alt font-weight-900">{fees}%</h2>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <div class="text-center bg-value rounded-3 px-3 py-2">
                                                <h2 class="py-2 font-alt font-weight-normal mb-0">WITHDRAWAL FEE</h2>
                                                <h2 class="font-alt font-weight-900">{fees}%</h2>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-3">
                                            <div class="text-center bg-value px-3 rounded-3 py-3">
                                                <h2 class="py-2 font-alt font-weight-normal mb-0">CONTRACT</h2>
                                                {!isAdmin ? 
                                                    <div class="d-block align-items-center justify-content-between text-center py-2 px-3"><a href={"https://testnet.bscscan.com/address/"+contractAddress} target="_blank" rel="noreferer" class="btn m-0 m-auto d-flex align-items-center justify-content-center text-center btn-theme rounded-pill text-white px-4 py-2 mb-lg-0"><small>View Contract</small></a></div>
                                                : 
                                                    <div class="d-block align-items-center justify-content-between text-center py-2 px-3"><a href="/my-admin" rel="noreferer" class="btn m-0 m-auto d-flex align-items-center justify-content-center text-center btn-theme rounded-pill text-white px-4 py-2 mb-lg-0"><small>Access Admin</small></a></div>
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    </>
  );
}