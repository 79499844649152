import React from "react";
import { useState, useEffect } from "react";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { loadContract } from "../utils/load-contract";
import swal from 'sweetalert';
import { customAlert } from "../components/customAlert";

export default function HbtStaking (props) {
    const [amountToDeposit,setAmountToDeposit] = useState();
    const [userStakedBalance,setUserStakedBalance] = useState(null);
    const [userHBTBalance,setUserHbtBalance] = useState(null);
    const [totalStaked,setTotalStaked] = useState(null);
    const [account, setAccount] = useState(null);
    const [reload, shouldReload] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [timeRemaining,setTimeRemaining] = useState(null);
    const [timeRemainingText,setTimeRemainingText] = useState(null);
    const [duration,setDuration] = useState(180);
    let transaction_type = 0;
    
    const reloadEffect = (value) => {shouldReload(reload);shouldReload(!reload);};
    let setAccountListener = null;
    setAccountListener = (provider) => {
        provider.on("accountsChanged", (accounts) => (window.location.href=''));
    };
    const amountChangeHandler = (event) => {
        setAmountToDeposit(event.target.value);
    };
    
    
    const switchHandler = (event) => {
        var nodes = event.target.parentElement.parentElement.childNodes;
        for(var i = 0;i<nodes.length;i++){
            event.target.parentElement.parentElement.childNodes[i].childNodes[0].classList.remove('active');
        }
        event.target.classList.add('active');
    }
    const durationChangeHandler = (event) => {
        const duration = parseInt(event.target.attributes['data-duration'].value);
        setDuration(duration);
        switchHandler(event);
    };
    useEffect(() => {
        var deadline = timeRemaining;
        const startTimer = () => {
            let x = setInterval(function() {
                var now = new Date().getTime();
                var t = deadline - now;
                var days = Math.floor(t / (1000 * 60 * 60 * 24));
                var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
                var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((t % (1000 * 60)) / 1000);
                const timeRemainingText = days+"d "+hours+"h "+minutes+"m "+seconds+"s";
                setTimeRemainingText(timeRemainingText);
                if (t < 0) {
                    clearInterval(x);
                    setTimeRemainingText("0D 0h 0m 0s");
                    document.getElementById('stakingTransaction').classList.remove('d-none');
                    setTimeRemaining(0);
                    document.getElementById('timerDiv').classList.add('d-none');
                }
            }, 1000);
            return () => clearInterval(x);
        }
        timeRemaining && startTimer();
    },[timeRemaining]);
    const typeChangeHandler = (event) => {
        transaction_type = parseInt(event.target.attributes['data-type'].value);
        if(transaction_type === 1){
            document.getElementById('amountWrapper').classList.add("d-none");
            document.getElementById('stakingTransaction').textContent = 'Withdraw';
            if(timeRemaining){
                document.getElementById('stakingTransaction').classList.add('d-none');
                document.getElementById('timerDiv').classList.remove('d-none');
            }else{
                document.getElementById('stakingTransaction').classList.remove('d-none');
                document.getElementById('timerDiv').classList.add('d-none');
            }
            
        }else{
            document.getElementById('amountWrapper').classList.remove("d-none");
            document.getElementById('stakingTransaction').textContent = 'Deposit';
            document.getElementById('stakingTransaction').classList.remove('d-none');
            document.getElementById('timerDiv').classList.add('d-none');
        }
        switchHandler(event);
    };
    const maxClickHandler = (event) => {
        let max = Math.min(1000 - userStakedBalance, userHBTBalance);
        document.getElementById('amountToDeposit').value = max;
        setAmountToDeposit(max);
    };
    const bscTestChainId = 97;
    function containsOnlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }
    
    let formatting_options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }
    const [web3Api, setWeb3Api] = useState({
        provider: null,
        web3: null,
        contract: null,
        HbtToken: null,
    });
    let switchNetwork = (null);
    switchNetwork = async (chainId) => {
        const provider = await detectEthereumProvider();
        if(provider){
            const web3 = new Web3(provider);
            const currentChainId = await web3.eth.getChainId();
            if (currentChainId !== chainId){
                try {
                    setRequesting(true);
                    await provider.request({
                        method:'wallet_switchEthereumChain',
                        params: [{chainId: web3.utils.toHex(chainId)}]
                    });
                    setRequesting(false);
                }catch(err){
                    console.log(`error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`);
                    if (err.code === 4902){
                        addNetwork(bscTestNetwork);
                    }
                }
            }
        }
        
    }

    const bscTestNetwork = {
            chainId:Web3.utils.toHex(bscTestChainId),
            chainName: "BSC Testnet",
            nativeCurrency: {
            name: "BSC Testnet",
            symbol: "tBNB", // 2-6 characters long
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls:["https://explorer.binance.org/smart-testnet"]
    }

    const addNetwork = async(networkDetails) => {
        try{
            const ethereum = await detectEthereumProvider();
            await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    networkDetails
                ]
            });
        }catch(err){
            console.log(`error ocuured while adding new chain with chainId:${networkDetails.chainId}, err: ${err.message}`);
            //swal("Error",`Error ocuured while adding new chain with chainId:${networkDetails.chainId}`,'error');
        }
    }

    useEffect(() => {
        const loadProvider = async () => {
            
            try{
                const provider = await detectEthereumProvider();
                if (provider) {
                    const contract = await loadContract("HedgeBotEcoSystem", provider);
                    setAccountListener(provider);
                    const abi = await fetch(`/contracts/HbtToken.json`);
                    const HbtTokenAbi = await abi.json();
                    let web3 = new Web3(provider);
                    let HbtTokenAddress = await contract.HBT();
                    const HbtToken = new web3.eth.Contract(HbtTokenAbi.abi, HbtTokenAddress,provider);
                    provider.request({ method: "eth_requestAccounts" });
                    setWeb3Api({
                        web3: web3,
                        provider,
                        contract,
                        HbtToken
                    });
                    setLoaded(true);
                } else {
                    customAlert("Error", "Please install Metamask.","","", "error");
                }
            }catch(e){
                console.log(e);
                await switchNetwork(bscTestChainId);
                //swal("Error", "Please install MetaMask and connect to BSC network.", "error");
            }
        };
        !loaded && loadProvider();
    },[loaded,switchNetwork,setAccountListener]);
    
    const stakingTransaction = async () => {
        const { contract,web3,HbtToken } = web3Api;
        if(transaction_type === 0){
            if(!containsOnlyNumbers(amountToDeposit)){
                customAlert("Error","Please enter a valid amount.","","","error");
            }else if(amountToDeposit < 10){
                customAlert("Error","","Please stake at least 10 HBT in order to enter in Hedgebot Ecosystem.","","error");
            }else{
                try{
                    document.getElementById('stakingTransaction').setAttribute("disabled","disabled");
                    document.getElementById('loader').classList.remove('d-none');
                    let hash = await HbtToken.methods.approve(contract.address, web3.utils.toWei(amountToDeposit+"", "ether")).send({from:account}).on('transactionHash',  (hash) => {
                        console.log(hash);
                    });
                    hash = await contract.stakeDeposit(web3.utils.toWei(amountToDeposit+'', "ether"),duration+"", { from: account });
                    document.getElementById('loader').classList.add('d-none');
                    console.log(hash);
                    swal("Success",amountToDeposit+ " HBT Staked successfully.","/assets/img/success.svg").then((value) => { window.location.href = ''; });
                    document.getElementById('stakingTransaction').removeAttribute("disabled");
                    document.getElementById('amountToDeposit').value = '';
                    reloadEffect();
                }catch(e){
                    document.getElementById('stakingTransaction').removeAttribute("disabled");
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error", "", e.message ,"", "error");
                }
            }
        }else{
            swal({
                title: "Are you sure?",
                text: "Once withdrawn, you will not be able to earn rewards in trading pool and your trading balance will be forfeited!",
                icon: "/assets/img/error.svg",
                buttons: ["Cancel", "Withdraw"],
                dangerMode: true,
            })
            .then(async (willWithdraw) => {
                if (willWithdraw) {
                    try{
                        document.getElementById('stakingTransaction').setAttribute("disabled","disabled");
                        await contract.withdrawStaking({ from: account });
                        document.getElementById('stakingTransaction').removeAttribute("disabled");
                        customAlert("Success","Withdrawal Successful","","","success");
                    }catch(e){
                        document.getElementById('stakingTransaction').removeAttribute("disabled");
                        customAlert("Error","", e.message ,"", "error");
                    }
                } else {
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Thank you for staying with HadgeBot Ecosystem.","","success");
                }
                document.getElementById('amountWrapper').classList.remove("d-none");
                document.getElementById('stakingTransaction').textContent = 'Deposit';
                document.getElementById('deposit').classList.add("active");
                document.getElementById('withdraw').classList.remove("active");
            });
        }
        reloadEffect();
    };

    useEffect(() => {
        try{
            
            const getData = async () => {
                document.getElementById('loader').classList.remove('d-none');
                const { contract , HbtToken } = web3Api;
                const convertToSafeNumber  = (BN) => {
                    let num = 0
                    try{
                        num = Math.round(web3Api.web3.utils.fromWei(BN, "ether") * 100) / 100;
                        num = num.toFixed(2);
                    }catch{
            
                    }
                    return num;
                };  
                const accounts = await web3Api.web3.eth.getAccounts();
                const userAccountAddress = accounts.length;
                if(userAccountAddress){
                    setAccount(accounts[0]);
                    document.getElementById('headerBtn').innerText = "("+accounts[0].substring(0,4)+ "..." + accounts[0].slice(-4) + ")";
                    // Get Total Staked
                    const userInfo = await contract.investors(accounts[0]);
                    const userStakedBalance = convertToSafeNumber(userInfo.total_staked) - 0;
                    setUserStakedBalance(userStakedBalance);
                    if(userInfo.lastStakingDeposit.toNumber() > 0){
                        const timeRemaining = userInfo.lastStakingDeposit.toNumber() - 0 + (userInfo.lastStakingDuration.toNumber() * 86400);
                        setTimeRemaining(timeRemaining*1000);
                    }
                    
                    const userHBTBalance = await HbtToken.methods.balanceOf(accounts[0]).call();
                    setUserHbtBalance(convertToSafeNumber(userHBTBalance) - 0);
                }
                const totalStaked = await contract.staked();
                setTotalStaked(convertToSafeNumber(totalStaked) - 0);
                document.getElementById('loader').classList.add('d-none');
            };
            web3Api.contract && !timeRemaining && getData() ;
        }catch(e){
            console.log(e.message);
            document.getElementById('loader').classList.add('d-none');
            //swal("Error","There is issue in connecting to your wallet","error");
        }
    },[web3Api,timeRemaining,reload]);
  return (
      <>
        {/* <!-- App features section--> */}
        <section id="features" class="bg-black">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-12 px-0 px-sm-3 order-lg-1 mb-5 mb-lg-0">
                        <div class="container-fluid px-2 text-white text-center">
                                <h2 class="font-weight-900">HBT Staking</h2>
                                <p class="font-weight-normal">Stake a minimum 10 HBT to all HedgeBot ecosystem strategies</p>
                        </div>
                        <div class="year-switcher py-3">
                            <ul class="px-2 py-1">
                                <li><button class="btn font-weight-normal text-white border-0 rounded-3" data-duration="90" onClick={durationChangeHandler}>90 Days</button></li>
                                <li><button class="btn font-weight-normal text-white border-0 rounded-3 active" data-duration="180" onClick={durationChangeHandler}>180 Days</button></li>
                                <li><button class="btn font-weight-normal text-white border-0 rounded-3 me-1" data-duration="360" onClick={durationChangeHandler}>360 Days</button></li>
                            </ul>
                        </div>
                        <div class="container-fluid px-2 text-white text-center">
                                <div class="row">
                                    <div class="col-lg-6 offset-lg-3 col-10 offset-1 bg-gray rounded-div py-2 my-4">
                                        <div class="year-switcher py-4">
                                            <ul class="px-2 py-1 bg-black">
                                                <li><button class="btn font-weight-normal text-white border-0 font-xs rounded-3 dataType active" data-type="0" id="deposit"  onClick={typeChangeHandler}>DEPOSIT</button></li>
                                                <li><button class="btn font-weight-normal text-white border-0 font-xs rounded-3 dataType" data-type="1" id="withdraw" onClick={typeChangeHandler}>WITHDRAW</button></li>
                                            </ul>
                                        </div>
                                        <div class="py-4 text-center">
                                            <p class="font-weight-normal">Balance</p>
                                            <p class="font-weight-700">{userStakedBalance ?  (userStakedBalance).toLocaleString(undefined,formatting_options) : "0"} HBT</p>
                                            <p class="font-weight-normal">Total Staked</p>
                                            <p class="font-weight-700">{userStakedBalance ?  (userStakedBalance).toLocaleString(undefined,formatting_options) : "0"} HBT</p>
                                            <div id="timerDiv" class="d-none">
                                                <p class="font-weight-normal">Time Left</p>
                                                <p class="font-weight-700"  id="timeLeft">{timeRemainingText ?  (timeRemainingText) : "180d 0h 0m 0s"}</p>
                                            </div>
                                        </div>
                                        <div class="px-md-5">
                                            <div id="amountWrapper">
                                                <label class="d-block font-xs px-3 font-weight-normal text-start">Wallet Balance: {userHBTBalance ?  (userHBTBalance).toLocaleString(undefined,formatting_options) : "0"} HBT</label>
                                                <div class="input-group staking-input mb-3 bg-black px-3 py-2" id="staking-input">
                                                    <div class="input-group-prepend">
                                                        <span class="bg-transparent border-0 font-weight-normal input-group-text small text-white px-1 py-1">
                                                            <img src="assets/favicon.ico" alt="Hedgebot Icon" class="mx-2" width="24" />
                                                        </span>
                                                    </div>
                                                    <input type="number" onChange={amountChangeHandler} id="amountToDeposit" min="10" pattern="[0-9]{10}" class="form-control p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Deposit" aria-label="Amount (to the nearest dollar)" />
                                                    <div class="input-group-append">
                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={maxClickHandler}>MAX</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center pb-5 pt-3">
                                                <button class="btn font-weight-normal btn-deposit" onClick={stakingTransaction} id="stakingTransaction">Deposit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </>
  );
}