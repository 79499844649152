import React from "react";
import { useState, useEffect } from "react";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import { loadContract } from "../utils/load-contract";
import swal from 'sweetalert';
import { customAlert } from "../components/customAlert";

export default function Owner (props) {
    const [account, setAccount] = useState(null);
    const [owner, setOwner] = useState(null);
    const [dev, setDev] = useState(null); // Deposit Fee Address
    const [creator, setCreator] = useState(null); // Withdrawal Fee Address
    const [partner, setPartner] = useState(null); // Partner Fee Address
    const [bot, setBot] = useState(null); // Bot Fee Address
    const [treasury, setTreasury] = useState(null); // Treasury Fee Address

    const [devFee, setDevFee] = useState(null); // Deposit Fee 
    const [creatorFee, setCreatorFee] = useState(null); // Withdrawal Fee 
    const [partnerFee, setPartnerFee] = useState(null); // Partner Fee 
    const [botFee, setBotFee] = useState(null); // Bot Fee 
    const [treasuryFee, setTreasuryFee] = useState(null); // Treasury Fee 
    const [refundFee, setRefundFee] = useState(null); // Refund Fee 

    const [dailyPercentage,setDailyPercentage] = useState(null);
    const [claimRefundDays,setClaimRefundDays] = useState(null);
    const [claimRewardsDays,setClaimRewardsDays] = useState(null);
    const [lowBalanceClass,setLowBalanceClass] = useState("bg-success");

    const [todayTotalRefunds,setTodayTotalRefunds] = useState(0);
    const [todayTotalRewards,setTodatTotalRewards] = useState(0);
    const [contractBalance,setContractBalance] = useState(0);
    const [totalLiability,setTotalLiability] = useState(0);
    const [stakingDeposits,setStakingDeposits] = useState(0);
    const [stakingWithdrawns,setStakingWithdrawns] = useState(0);

    const [reload, shouldReload] = useState(false);
    const [requesting, setRequesting] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [partnerAddress, setPartnerAddress] = useState('');
    const [botAddress, setBotAddress] = useState('');
    const [treasuryAddress, setTreasuryAddress] = useState('');

    const [partnerPercentage, setPartnerPercentage] = useState('');
    const [botPercentage, setBotPercentage] = useState('');
    const [treasuryPercentage, setTreasuryPercentage] = useState('');

    const [depositFeeAddress, setDepositFeeAddress] = useState('');
    const [withdrawalFeeAddress, setWithdrawalFeeAddress] = useState('');
    const [depositFee, setDepositFee] = useState('');
    const [withdrawalFee, setWithdrawalFee] = useState('');
    
    const [claimRefundInDays,setClaimRefundInDays] = useState('');
    const [claimRewardInDays,setClaimRewardInDays] = useState('');
    const [exitTradingFee,setExitTradingFee] = useState('');
    const [dailyRewardsPercentage,setDailyRewardsPercentage] = useState('');
    
    const [pauseTradingAddress, setPauseTradingAddress] = useState('');
    const [unpauseTradingAddress, setUnpauseTradingAddress] = useState('');
    const [bannedAddress, setBannedAddress] = useState('');
    const [unbannedAddress, setUnbannedAddress] = useState('');

    const [isDepoPaused,setIsDepoPaused] = useState(0);
    const [isPayoutPaused,setIsPayoutPaused] = useState(0);
    const [isStakingDepoPaused,setIsStakingDepoPaused] = useState(0);
    const [isRefundPaused,setIsRefundPaused] = useState(0);
    const [ownershipAddress,setOwnershipAddress] = useState('');

    const [depositAmount,setDepositAmount] = useState('');

    const reloadEffect = (value) => {shouldReload(reload);shouldReload(!reload);};
    let setAccountListener = null;
    setAccountListener = (provider) => {
        provider.on("accountsChanged", (accounts) => (window.location.href=''));
    };
    
    const bscTestChainId = 97;
    function containsOnlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }
    
    let formatting_options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }
    const [web3Api, setWeb3Api] = useState({
        provider: null,
        web3: null,
        contract: null,
        UsdcToken: null,
    });
    let switchNetwork = (null);
    switchNetwork = async (chainId) => {
        const provider = await detectEthereumProvider();
        if(provider){
            const web3 = new Web3(provider);
            const currentChainId = await web3.eth.getChainId();
            if (currentChainId !== chainId){
                try {
                    setRequesting(true);
                    await provider.request({
                        method:'wallet_switchEthereumChain',
                        params: [{chainId: web3.utils.toHex(chainId)}]
                    });
                    setRequesting(false);
                }catch(err){
                    console.log(`error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`);
                    if (err.code === 4902){
                        addNetwork(bscTestNetwork);
                    }
                }
            }
        }
        
    }

    const bscTestNetwork = {
            chainId:Web3.utils.toHex(bscTestChainId),
            chainName: "BSC Testnet",
            nativeCurrency: {
            name: "BSC Testnet",
            symbol: "tBNB", // 2-6 characters long
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls:["https://explorer.binance.org/smart-testnet"]
    }

    const addNetwork = async(networkDetails) => {
        try{
            const ethereum = await detectEthereumProvider();
            await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    networkDetails
                ]
            });
        }catch(err){
            console.log(`error ocuured while adding new chain with chainId:${networkDetails.chainId}, err: ${err.message}`);
            //swal("Error",`Error ocuured while adding new chain with chainId:${networkDetails.chainId}`,'error');
        }
    }

    useEffect(() => {
        const loadProvider = async () => {
            
            try{
                const provider = await detectEthereumProvider();
                if (provider) {
                    const contract = await loadContract("HedgeBotEcoSystem", provider);
                    setAccountListener(provider);
                    const abi = await fetch(`/contracts/UsdcToken.json`);
                    const UsdcTokenAbi = await abi.json();
                    let web3 = new Web3(provider);
                    let UsdcTokenAddress = await contract.USD();
                    const UsdcToken = new web3.eth.Contract(UsdcTokenAbi.abi, UsdcTokenAddress,provider);
                    provider.request({ method: "eth_requestAccounts" });
                    setWeb3Api({
                        web3: web3,
                        provider,
                        contract,
                        UsdcToken
                    });
                    setLoaded(true);
                } else {
                    customAlert("Error", "Please install Metamask.","","", "error");
                }
            }catch(e){
                console.log(e);
                await switchNetwork(bscTestChainId);
                //swal("Error", "Please install MetaMask and connect to BSC network.", "error");
            }
        };
        !loaded && loadProvider();
    },[loaded,switchNetwork,setAccountListener]);
    
    useEffect(() => {
        try{
            
            const getData = async () => {
                document.getElementById('loader').classList.remove('d-none');
                const { contract  } = web3Api;
                const convertToSafeNumber  = (BN) => {
                    let num = 0
                    try{
                        num = Math.round(web3Api.web3.utils.fromWei(BN, "ether") * 100) / 100;
                        num = num.toFixed(2);
                    }catch{
            
                    }
                    return num;
                };  
                const accounts = await web3Api.web3.eth.getAccounts();
                const userAccountAddress = accounts.length;
                if(userAccountAddress){
                    setAccount(accounts[0]);
                    const owner = await contract.getOwner();
                    if(owner.toUpperCase() !== accounts[0].toUpperCase()){
                        window.location.href = "/contract";
                        return;
                    }else{
                        setOwner(owner);
                        const bot = await contract.botTrader();
                        const dev = await contract.dev();
                        const creator = await contract.creator();
                        const partner = await contract.partnerTrader();
                        const treasury = await contract.treasury();
                        
                        setBot(bot);
                        setDev(dev);
                        setCreator(creator);
                        setPartner(partner);
                        setTreasury(treasury);

                        const botFee = await contract.botFee();
                        const devFee = await contract.devFee();
                        const creatorFee = await contract.withdrawalFee();
                        const partnerFee = await contract.partnerFee();
                        const treasuryFee = await contract.treasuryFee();
                        const refundFee = await contract.refundFee();

                        setBotFee((botFee.toNumber()/1000)*100);
                        setDevFee((devFee.toNumber()/1000)*100);
                        setCreatorFee((creatorFee.toNumber()/1000)*100);
                        setPartnerFee((partnerFee.toNumber()/1000)*100);
                        setTreasuryFee((treasuryFee.toNumber()/1000)*100);
                        setRefundFee((refundFee.toNumber()/1000)*100);

                        const tarifs = await contract.tarifs(0);
                        const claimRewardsDays = await contract.numDays();
                        const claimRefundDays = await contract.numDays2();

                        setDailyPercentage((tarifs.percent.toNumber()/100));
                        setClaimRewardsDays(claimRewardsDays.toNumber());
                        setClaimRefundDays(claimRefundDays.toNumber());

                        const todayTotalRefunds = await contract.todayClaimableRefunds({from:accounts[0]});
                        const todayTotalRewards = await contract.todayClaimableRewards({from:accounts[0]});
                        const totalLiability = (convertToSafeNumber(todayTotalRefunds) - 0) + (convertToSafeNumber(todayTotalRewards) - 0);
                        const contractBalance = await contract.getContractBalance();
                        const stakingDeposits = await contract.staked();

                        const stakingWithdrawns = await contract.stake_withdrawn();
                        setTodayTotalRefunds(convertToSafeNumber(todayTotalRefunds) - 0);
                        setTodatTotalRewards(convertToSafeNumber(todayTotalRewards) - 0);
                        setContractBalance(convertToSafeNumber(contractBalance) - 0);
                        setTotalLiability(totalLiability);
                        setStakingDeposits(convertToSafeNumber(stakingDeposits) - 0);
                        setStakingWithdrawns(convertToSafeNumber(stakingWithdrawns) - 0);

                        if((convertToSafeNumber(contractBalance) - 0) < totalLiability){
                            setLowBalanceClass("bg-danger");
                        }
                        const isDepoPaused = await contract.isDepoPaused();
                        setIsDepoPaused(isDepoPaused.toNumber());

                        const isRefundPaused = await contract.isRefundPaused();
                        setIsRefundPaused(isRefundPaused.toNumber());
                        
                        const isPayoutPaused = await contract.isPayoutPaused();
                        setIsPayoutPaused(isPayoutPaused.toNumber());

                        const isStakingDepoPaused = await contract.isStakingDepoPaused();
                        setIsStakingDepoPaused(isStakingDepoPaused.toNumber());
                    }
                    
                }
                document.getElementById('loader').classList.add('d-none');
            };
            web3Api.contract && getData() ;
        }catch(e){
            console.log(e.message);
            document.getElementById('loader').classList.add('d-none');
            //swal("Error","There is issue in connecting to your wallet","error");
        }
    },[web3Api,reload]);

    const changePartnerAddress = async () =>{
        const { contract } = web3Api;
        if(!partnerAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once changed, old partner address will not receive any percentage!",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setPartnerTrader(partnerAddress, {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Partner address changed successfully","","success");
                    setPartnerAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const changePartnerPercentage = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(partnerPercentage)){customAlert("Error","","Please enter a valid percentage","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setPartnerTraderFee((partnerPercentage*10), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Partner percentage changed successfully","","success");
            setPartnerPercentage('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }

    const changeBotAddress = async () =>{
        const { contract } = web3Api;
        if(!botAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once changed, old Bot address will not receive any percentage!",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setBotTrader(botAddress, {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Bot address changed successfully","","success");
                    setBotAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const changeBotPercentage = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(botPercentage)){customAlert("Error","","Please enter a valid percentage","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setBotTraderFee((botPercentage*10), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Bot percentage changed successfully","","success");
            setBotPercentage('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }

    const changeTreasuryAddress = async () =>{
        const { contract } = web3Api;
        if(!treasuryAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once changed, old treasury address will not receive any percentage!",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setTreasury(treasuryAddress, {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Treasury address changed successfully","","success");
                    setTreasuryAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const changeTreasuryPercentage = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(treasuryPercentage)){customAlert("Error","","Please enter a valid percentage","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setTreasuryFee((treasuryPercentage*10), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Treasury percentage changed successfully","","success");
            setTreasuryPercentage('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }
    const changeDepositFeeAddress = async () =>{
        const { contract } = web3Api;
        if(!depositFeeAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once changed, old deposit fee address will not receive any fee!",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setDev(depositFeeAddress, {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Deposit fee address changed successfully","","success");
                    setDepositFeeAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const changeDepositFee = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(depositFee)){customAlert("Error","","Please enter a valid percentage","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setDevFee((depositFee*10), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Deposit fee changed successfully","","success");
            setDepositFee('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }
    const changeWithdrawalFeeAddress = async () =>{
        const { contract } = web3Api;
        if(!withdrawalFeeAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once changed, old withdrawal fee address will not receive any fee!",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setWithdrawalFeeAddress(withdrawalFeeAddress, {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Withdrawal fee address changed successfully","","success");
                    setWithdrawalFeeAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const changeWithdrawalFee = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(withdrawalFee)){customAlert("Error","","Please enter a valid percentage","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setCreatorFee((withdrawalFee*10), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Withdrawal fee changed successfully","","success");
            setWithdrawalFee('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }
    const changeRefundFee = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(exitTradingFee)){customAlert("Error","","Please enter a valid percentage","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setRefundFee((exitTradingFee*10), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Refund fee changed successfully","","success");
            setExitTradingFee('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }
    const changeDailyRewards = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(dailyRewardsPercentage*100)){customAlert("Error","","Please enter a valid percentage","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setPercentage((dailyRewardsPercentage*100), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Daily reward percentage changed successfully","","success");
            setDailyRewardsPercentage('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }
    const changeClaimRewards = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(claimRewardInDays)){customAlert("Error","","Please enter valid number of days","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setDays((claimRewardInDays), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Days to claim rewards changed successfully","","success");
            setClaimRewardInDays('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }

    const changeClaimRefund = async () =>{
        const { contract } = web3Api;
        if(!containsOnlyNumbers(claimRefundInDays)){customAlert("Error","","Please enter valid number of days","","error");return;}
        try{
            document.getElementById('loader').classList.remove('d-none');
            await contract.setDays2((claimRefundInDays), {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert("Success","","Days to claim refund changed successfully","","success");
            setClaimRefundInDays('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }
    const pauseDepositAddress = async () =>{
        const { contract } = web3Api;
        if(!pauseTradingAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once paused, this user will not be able to deposit any amount in trading.",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.pauseUnpauseTrading(pauseTradingAddress,1, {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Wallet is not allowed for trading deposit now","","success");
                    setPauseTradingAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const unpauseDepositAddress = async () =>{
        const { contract } = web3Api;
        if(!unpauseTradingAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once unpaused, this user will be allowed to deposit in trading.",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.pauseUnpauseTrading(unpauseTradingAddress, 0 , {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Wallet is allowed for trading deposit now","","success");
                    setUnpauseTradingAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const banAddress = async () =>{
        const { contract } = web3Api;
        if(!bannedAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once banned, this user will not be able to withdraw.",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.banUnbanTrader(bannedAddress,1, {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Wallet is not allowed for withdrawal now","","success");
                    setBannedAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const unbanAddress = async () =>{
        const { contract } = web3Api;
        if(!unbannedAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "Once unbanned, this user will be allowed to withdraw.",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.banUnbanTrader(unbannedAddress, 0 , {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Wallet is allowed for withdrawal now","","success");
                    setUnbannedAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const pauseDepo = async () =>{
        const { contract } = web3Api;
        let text = (isDepoPaused) ? "This will allow everyone for deposit" : "This will restrict everyone for deposit";
        let message = (isDepoPaused) ? "Deposit is allowed for everyone now" : "Deposit is restricted for everyone now";
        let status = (!isDepoPaused) ? 1 : 0;
        swal({
            title: "Are you sure?",
            text: text,
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setDepoPause(status , {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","",message,"","success");
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const pausePayout = async () =>{
        const { contract } = web3Api;
        let text = (isPayoutPaused) ? "This will allow everyone to withdraw rewards" : "This will restrict everyone to withdraw rewards";
        let message = (isPayoutPaused) ? "Payout is allowed for everyone now" : "Payout is restricted for everyone now";
        let status = (!isPayoutPaused) ? 1 : 0;
        swal({
            title: "Are you sure?",
            text: text,
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setPayoutPause(status , {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","",message,"","success");
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const pauseRefund = async () =>{
        const { contract } = web3Api;
        let text = (isRefundPaused) ? "This will allow everyone to withdraw refunds" : "This will restrict everyone to withdraw refunds";
        let message = (isRefundPaused) ? "Refund is allowed for everyone now" : "Refund is restricted for everyone now";
        let status = (!isRefundPaused) ? 1 : 0;
        swal({
            title: "Are you sure?",
            text: text,
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setRefundPause(status , {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","",message,"","success");
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const pauseStaking = async () =>{
        const { contract } = web3Api;
        let text = (isStakingDepoPaused) ? "This will allow everyone to stake HBT" : "This will restrict everyone to stake HBT";
        let message = (isStakingDepoPaused) ? "Staking is allowed for everyone now" : "Staking is restricted for everyone now";
        let status = (!isStakingDepoPaused) ? 1 : 0;
        swal({
            title: "Are you sure?",
            text: text,
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.setStakingDepoPause(status , {"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","",message,"","success");
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const transferOwnership = async () => {
        const { contract } = web3Api;
        if(!ownershipAddress){customAlert("Error","","Please enter a valid address","","error");return;}
        swal({
            title: "Are you sure?",
            text: "You will lose your ownership and access to admin. Also you won't be able to undo this.",
            icon: "/assets/img/error.svg",
            buttons: ["Cancel", "Proceed"],
            dangerMode: true,
        })
        .then(async (proceed) => {
            if (proceed) {
                try{
                    document.getElementById('loader').classList.remove('d-none');
                    await contract.transferOwnership(ownershipAddress,{"from":account});
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Success","","Ownership transferred successfully.","","success");
                    setOwnershipAddress('');
                    reloadEffect();
                }catch(e){
                    document.getElementById('loader').classList.add('d-none');
                    customAlert("Error","", e.message ,"", "error");
                }
            } 
        });
    }
    const adminDeposit = async () =>{
        const {web3, contract,UsdcToken } = web3Api;

        if(!containsOnlyNumbers(depositAmount)){customAlert("Error","","Please enter a valid amount","","error");return;}
        try{
            let amount =  web3.utils.toWei(depositAmount, "ether");
            document.getElementById('loader').classList.remove('d-none');
            let hash = await UsdcToken.methods.approve(contract.address, amount).send({from:account}).on('transactionHash',  (hash) => {
                console.log(hash);
            });
            await contract.adminDeposit(amount, {"from":account});
            document.getElementById('loader').classList.add('d-none');
            customAlert(depositAmount+ " USDC","Deposited Successfully","","","success");
            setDepositAmount('');
            reloadEffect();
        }catch(e){
            document.getElementById('loader').classList.add('d-none');
            customAlert("Error","", e.message ,"", "error");
        }
    }
  return (
      <>
        <div class="text-center py-4 font-weight-normal ">
            <div class="container px-5">
                <div class="row gx-5 mt-5 justify-content-center">
                    <div class="col-md-1"></div>
                    <div class="col-md-10 bg-gray rounded-div p-5">
                        <div class="row">
                            <div class="col-12 col-xl-3 bg-black flex-column nav nav-pills px-4 py-5 rounded-10" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button class="nav-link active btn btn-theme mb-2 text-white font-weight-normal font-xs rounded-10" id="v-pills-overview-tab" data-bs-toggle="pill" data-bs-target="#v-pills-overview" type="button" role="tab" aria-controls="v-pills-overview" aria-selected="true">DASHBOARD</button>
                                <button class="nav-link btn btn-theme mb-2 text-white font-weight-normal font-xs rounded-10" id="v-pills-allocation-tab" data-bs-toggle="pill" data-bs-target="#v-pills-allocation" type="button" role="tab" aria-controls="v-pills-allocation" aria-selected="false">ALLOCATION</button>
                                <button class="nav-link btn btn-theme mb-2 text-white font-weight-normal font-xs rounded-10" id="v-pills-trading-tab" data-bs-toggle="pill" data-bs-target="#v-pills-trading" type="button" role="tab" aria-controls="v-pills-trading" aria-selected="false">TRADING</button>
                                <button class="nav-link btn btn-theme mb-2 text-white font-weight-normal font-xs rounded-10" id="v-pills-deposit-tab" data-bs-toggle="pill" data-bs-target="#v-pills-deposit" type="button" role="tab" aria-controls="v-pills-deposit" aria-selected="false">DEPOSIT</button>
                                <button class="nav-link btn btn-theme mb-2 text-white font-weight-normal font-xs rounded-10" id="v-pills-withdraw-tab" data-bs-toggle="pill" data-bs-target="#v-pills-withdraw" type="button" role="tab" aria-controls="v-pills-withdraw" aria-selected="false">WITHDRAW</button>
                                <button class="nav-link btn btn-theme mb-2 text-white font-weight-normal font-xs rounded-10" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">SETTINGS</button>
                                <button class="nav-link btn btn-theme mb-2 text-white font-weight-normal font-xs rounded-10" id="v-pills-misc-tab" data-bs-toggle="pill" data-bs-target="#v-pills-misc" type="button" role="tab" aria-controls="v-pills-misc" aria-selected="false">MISCELLANEOUS</button>
                            </div>
                            <div class="col-12 col-xl-9 tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-overview" role="tabpanel" aria-labelledby="v-pills-overview-tab">
                                    <div class="row">
                                        <h4 class="font-weight-normal mt-2 text-start mb-2">Dashboard</h4>
                                        {/* Claimable Rewards */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Claimable Rewards <span class="badge bg-primary font-weight-bold float-end">Today</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <h4 class="mb-0 mb-3 px-3 text-center text-center font-alt font-weight-900">${(todayTotalRewards).toLocaleString(undefined,formatting_options)}<br />USDC</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Available Refunds */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Available Refunds <span class="badge bg-primary font-weight-bold float-end">Today</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <h4 class="mb-0 mb-3 px-3 text-center text-center font-alt font-weight-900">${(todayTotalRefunds).toLocaleString(undefined,formatting_options)}<br />USDC</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Total Liability */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Total Liability <span class="badge bg-primary font-weight-bold float-end">Today</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <h4 class="mb-0 mb-3 px-3 text-center text-center font-alt font-weight-900">${(totalLiability).toLocaleString(undefined,formatting_options)}<br />USDC</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Contract Balance */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div className={"card-body p-0 shadow rounded-10  "+lowBalanceClass+" "}>
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Contract Balance <span className={"badge "+lowBalanceClass+" font-weight-bold float-end"}>Live</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <h4 class="mb-0 mb-3 px-3 text-center text-center font-alt font-weight-900">${(contractBalance).toLocaleString(undefined,formatting_options)}<br />USDC</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* STAKING DEPOSITS */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div className={"card-body p-0 shadow rounded-10"}>
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Staking Deposits <span className={"badge bg-primary font-weight-bold float-end"}>Total</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <h4 class="mb-0 mb-3 px-3 text-center text-center font-alt font-weight-900">${(stakingDeposits).toLocaleString(undefined,formatting_options)}<br />HBT</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* STAKING Withdrawals */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div className={"card-body p-0 shadow rounded-10"}>
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Staking Withdrawals <span className={"badge bg-primary font-weight-bold float-end"}>Total</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <h4 class="mb-0 mb-3 px-3 text-center text-center font-alt font-weight-900">${(stakingWithdrawns).toLocaleString(undefined,formatting_options)}<br />HBT</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-allocation" role="tabpanel" aria-labelledby="v-pills-allocation-tab">
                                    <div class="row">
                                        <h4 class="font-weight-normal text-start mt-2 mb-2">Allocation</h4>
                                        {/* Partner Address */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Partner<span class="badge bg-primary font-weight-bold float-end">Address</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Address: {partner}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text" class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" value={partnerAddress} onChange={event => setPartnerAddress(event.target.value)} placeholder="Enter wallet address" aria-label="Partner Address" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changePartnerAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Partner Percentage */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Partner<span class="badge bg-primary font-weight-bold float-end">Percentage (%)</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Percentage: {partnerFee}%</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" max="100" pattern="[0-9]{10}"  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter percentage" value={partnerPercentage} onChange={event => setPartnerPercentage(event.target.value)} aria-label="Partner Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changePartnerPercentage}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Bot Address */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Bot<span class="badge bg-primary font-weight-bold float-end">Address</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Address: {bot}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text" class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter wallet address" value={botAddress} onChange={event => setBotAddress(event.target.value)} aria-label="Partner Address" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeBotAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Bot Percentage */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Bot<span class="badge bg-primary font-weight-bold float-end">Percentage (%)</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Percentage: {botFee}%</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" max="100" pattern="[0-9]{10}"  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" value={botPercentage} onChange={event => setBotPercentage(event.target.value)} placeholder="Enter percentage" aria-label="Partner Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeBotPercentage}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Treasury Address */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Treasury<span class="badge bg-primary font-weight-bold float-end">Address</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Address: {treasury}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text" class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" value={treasuryAddress} onChange={event => setTreasuryAddress(event.target.value)} placeholder="Enter wallet address" aria-label="Treasury Address" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeTreasuryAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Treasury Percentage */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Treasury<span class="badge bg-primary font-weight-bold float-end">Percentage (%)</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Percentage: {treasuryFee}%</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" max="100" pattern="[0-9]{10}"  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter percentage" value={treasuryPercentage} onChange={event => setTreasuryPercentage(event.target.value)} aria-label="Treasury Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeTreasuryPercentage}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-trading" role="tabpanel" aria-labelledby="v-pills-trading-tab">
                                    <div class="row">
                                        <h4 class="font-weight-normal text-start mt-2 mb-2">Trading</h4>
                                        {/* Pause Trading */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Pause Trading</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text"  value={pauseTradingAddress} onChange={event => setPauseTradingAddress(event.target.value)} class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter wallet address" aria-label="Partner Address" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={pauseDepositAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Unpause Trading */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Unpause Trading</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text"  value={unpauseTradingAddress} onChange={event => setUnpauseTradingAddress(event.target.value)} class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter address" aria-label="Partner Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={unpauseDepositAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Ban Wallet */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Ban Wallet</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text"  value={bannedAddress} onChange={event => setBannedAddress(event.target.value)} class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter wallet address" aria-label="Ban Wallet" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={banAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* UnBan Wallet */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Unban Wallet</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text"  value={unbannedAddress} onChange={event => setUnbannedAddress(event.target.value)} class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter wallet address" aria-label="Unban Wallet" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={unbanAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-deposit" role="tabpanel" aria-labelledby="v-pills-deposit-tab">
                                    {/*  Deposit */}
                                    <div class="row">
                                        <h4 class="font-weight-normal text-start mt-2 mb-2">Deposit</h4>
                                        {/* Pause Deposit */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Pause/Unpause Deposit</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner text-start mx-1">Current Status: {isDepoPaused ? "PAUSED": "UNPAUSED"}</h6>
                                                                <div class="input-group mb-3 bg-black p-1">
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={pauseDepo}>{isDepoPaused ? "UNPAUSE": "PAUSE"} DEPOSIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Deposit in Contract */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Contract Deposit<span class="badge bg-primary font-weight-bold float-end">Amount</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Contract Balance: {contractBalance} USDC</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" pattern="[0-9]{10}"  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" value={depositAmount} onChange={event => setDepositAmount(event.target.value)} placeholder="Enter Amount" aria-label="Amount to deposit" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={adminDeposit}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="tab-pane fade" id="v-pills-withdraw" role="tabpanel" aria-labelledby="v-pills-withdraw-tab">
                                    {/* Rewards / Refunds */}
                                    <div class="row">
                                        <h4 class="font-weight-normal text-start mt-2 mb-2">Withdraw</h4>
                                        {/* Pause Rewards */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Pause/Unpause Rewards</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner text-start mx-1">Current Status: {isPayoutPaused ? "PAUSED": "UNPAUSED"}</h6>
                                                                <div class="input-group mb-3 bg-black p-1">
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={pausePayout}>{!isPayoutPaused ? "PAUSE": "UNPAUSE"} REWARDS</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Pause Refunds */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Pause/Unpause Refunds</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner text-start mx-1">Current Status: {isRefundPaused ? "PAUSED": "UNPAUSED"}</h6>
                                                                <div class="input-group mb-3 bg-black p-1">
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={pauseRefund}>{!isRefundPaused ? "PAUSE": "UNPAUSE"} REFUNDS</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <div class="row">
                                        <h4 class="font-weight-normal text-start mt-2 mb-2">Settings</h4>
                                        {/* Deposit Fee */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Deposit Fee<span class="badge bg-primary font-weight-bold float-end">Percentage (%)</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Fee: {devFee}%</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" max="100" pattern="[0-9]{10}"  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" value={depositFee} onChange={event => setDepositFee(event.target.value)}  placeholder="Enter percentage" aria-label="Partner Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeDepositFee}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Deposit Fee Wallet Address */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Deposit Fee Wallet<span class="badge bg-primary font-weight-bold float-end">Address</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Address: {dev}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text" class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter wallet address" value={depositFeeAddress} onChange={event => setDepositFeeAddress(event.target.value)}  aria-label="Deposit Fee Wallet Address" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeDepositFeeAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Withdrawal Fee */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Withdrawal Fee<span class="badge bg-primary font-weight-bold float-end">Percentage (%)</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Fee: {creatorFee}%</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" max="100" pattern="[0-9]{10}"  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter percentage" value={withdrawalFee} onChange={event => setWithdrawalFee(event.target.value)}  aria-label="Partner Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeWithdrawalFee}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Withdrawal Fee Wallet Address */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Withdrawal Fee Wallet<span class="badge bg-primary font-weight-bold float-end">Address</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Address: {creator}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text" class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter wallet address" value={withdrawalFeeAddress} onChange={event => setWithdrawalFeeAddress(event.target.value)}  aria-label="Withdrawal Fee Wallet Address" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeWithdrawalFeeAddress}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Refund Fee */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Refund Fee<span class="badge bg-primary font-weight-bold float-end">Percentage (%)</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Fee: {refundFee}%</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" max="100" pattern="[0-9]{10}"  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white"  value={exitTradingFee} onChange={event => setExitTradingFee(event.target.value)}  placeholder="Enter percentage" aria-label="Partner Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeRefundFee}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Daily Rewards */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Daily Rewards<span class="badge bg-primary font-weight-bold float-end">Percentage (%)</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Percentage: {dailyPercentage}%</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" pattern="[0-9]{10}" max="100" value={dailyRewardsPercentage} onChange={event => setDailyRewardsPercentage(event.target.value)}  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter percentage" aria-label="Partner Percentage" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeDailyRewards}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Claim Rewards */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Claim Rewards<span class="badge bg-primary font-weight-bold float-end">No. of Days</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current number of days: {claimRewardsDays}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number"  min="0" pattern="[0-9]{10}" value={claimRewardInDays} onChange={event => setClaimRewardInDays(event.target.value)}  class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter days" aria-label="Claim Rewards" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeClaimRewards}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Claim Refunds */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Claim Refunds<span class="badge bg-primary font-weight-bold float-end">No. of Days</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current number of days: {claimRefundDays}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="number" min="0" pattern="[0-9]{10}" value={claimRefundInDays} onChange={event => setClaimRefundInDays(event.target.value)} class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white" placeholder="Enter days" aria-label="Claim Refunds" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={changeClaimRefund}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-misc" role="tabpanel" aria-labelledby="v-pills-misc-tab">
                                    {/* Pause Staking Deposit */}
                                    <div class="row">
                                        <h4 class="font-weight-normal text-start mt-2 mb-2">Miscellaneous</h4>
                                        {/* Staking Deposit */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Pause/Unpause Staking</h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner text-start mx-1">Current Status: {isStakingDepoPaused ? "PAUSED": "UNPAUSED"}</h6>
                                                                <div class="input-group mb-3 bg-black p-1">
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={pauseStaking}>{!isStakingDepoPaused ? "PAUSE": "UNPAUSE"} STAKING</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Transfer Ownership */}
                                        <div class="col-sm-12 col-lg-6 mb-4">
                                            <div class="card h-100 rounded-10">
                                                <div class="card-body p-0 shadow rounded-10">
                                                    <div class="row justify-content-between align-items-start mb-4">
                                                        <div class="col text-start">
                                                            <h5 class="font-weight-bold mb-0 p-3 bg-theme rounded-3 border-bottom">Transfer Ownership<span class="badge bg-danger font-weight-bold float-end">Address</span></h5>
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-between align-items-center mb-0">
                                                        <div class="col">
                                                            <div class="mb-0 mb-3 px-2 text-center text-center font-alt font-weight-900">
                                                                <h6 class="font-small font-weight-thinner">Current Owner: {owner}</h6>
                                                                <div class="input-group mb-3 bg-black p-1 border rounded-3">
                                                                    <input type="text" class="form-control font-xs no-focus-input p-1 bg-transparent border-0 font-weight-normal form-control p-1 text-white"  value={ownershipAddress} onChange={event => setOwnershipAddress(event.target.value)}  placeholder="Enter wallet address" aria-label="Owner Address" />
                                                                    <div class="input-group-append">
                                                                        <span class="bg-theme border-0 btn font-weight-normal font-xs input-group-text small px-2 py-1 text-white" onClick={transferOwnership}>SUBMIT</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>
            </div>
        </div>
        
      </>
  );
}