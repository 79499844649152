import React from "react";

export default function Header(props) {
  // const [web3Api, setWeb3Api] = useState({
  //   web3: null,
  //   provider: null,
  // });
  
  // const [account, setAccount] = useState(null);
  // const setAccountListener = (provider) => {
  //   provider.on("accountsChanged", (accounts) => setAccount(accounts[0]));
  // };
  // useEffect(() => {
  //   const loadProvider = async () => {
  //     try{
  //       const provider = await detectEthereumProvider();
  //       if (provider) {
          
  //         provider.request({ method: "eth_requestAccounts" });
  //         setAccountListener(provider);
  //         setWeb3Api({
  //           web3: new Web3(provider),
  //           provider
  //         });
  //       } else {
  //         console.log("Please install MetaMask!");
  //       }
  //     }catch(e){
  //       console.log(e.message);
  //       console.log("Please install MetaMask and connect to BSC network");
  //     }
      
  //   };
  //   loadProvider();
  // },[]);
  
  // useEffect(() => {
  //   const getAccount = async () => {
  //     const accounts = await web3Api.web3.eth.getAccounts();
  //     setAccount(accounts[0]);
  //   };
  //   web3Api.web3 && getAccount();
  // }, [web3Api]);
  return (
        <nav class="navbar navbar-expand-lg navbar-light shadow-sm" id="mainNav">
            <div class="container px-4 px-md-5 d-lg-flex align-items-lg-end">
                <a class="navbar-brand fw-bold" href="/"><img src="assets/img/transparent-logo2.webp" class="img-fluid" alt="logo" /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="bi-list text-white"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ms-auto me-0 me-lg-4 my-3 my-lg-0"> 
                        <li class="nav-item"><a class="nav-link mx-lg-0" href="/">HOME</a></li>
                        <li class="nav-item">
                          <a class="nav-link mx-lg-3" >ABOUT US</a>
                          <ul class="dropdown-menu">
                            <li class="nav-item"><a class="nav-link mx-lg-0" target="_blank" rel="noreferrer"  href="assets/white-paper/hedgebot-whitepaper.pdf">WHITE PAPER</a></li>
                          </ul>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link mx-lg-0 cursor-pointer" >ECOSYSTEM</a>
                          <ul class="dropdown-menu">
                            <li class="nav-item"><a class="nav-link mx-lg-0" href="/contract">TRADING POOL</a></li>
                            <li class="nav-item"><a class="nav-link mx-lg-0" href="/trading-bot">TRADING BOT</a></li>
                            <li class="nav-item"><a class="nav-link mx-lg-0" href="/hedgebot-tokens" rel="noreferrer">HEDGEBOT TOKEN</a></li>
                            <li class="nav-item" id="hbt-swap-nav"><a  class="nav-link mx-lg-0" href="/swap" rel="noreferrer">HBT SWAP</a></li>
                            <li class="nav-item"><a class="nav-link mx-lg-0" rel="noreferrer"  href="/hbt-staking">HBT STAKING</a></li>
                          </ul>
                        </li>
                        <li class="nav-item"><a class="nav-link mx-lg-0" href="/shop">SHOP</a></li>
                        <li class="nav-item">
                          <a class="nav-link mx-lg-0" >FAQ</a>
                          <ul class="dropdown-menu">
                            <li class="nav-item"><a class="nav-link mx-lg-0" href="/updates" rel="noreferrer" >UPDATES</a></li>
                            <li class="nav-item"><a class="nav-link mx-lg-0" href="http://support.hedgebot.app" target="_blank" rel="noreferrer" >KNOWLEDGE BASE</a></li>
                            <li class="nav-item"><a class="nav-link mx-lg-0" href="/roadmap" rel="noreferrer" >ROADMAP</a></li>
                          </ul>
                        </li>
                    </ul>
                    <a href="/swap" class="btn btn-buy px-4 mb-3 mb-sm-0 py-1 mb-lg-0 rounded-pill">
                        <span class="d-flex align-items-center text-white">
                            <span>Buy HBT</span>
                        </span>
                    </a>
                    <a class="align-items-center btn btn-theme d-flex rounded-pill justify-content-center mb-lg-0 ms-sm-3 px-4 py-2" href="/contract">
                        <span class="text-center text-white"  id="headerBtn">
                          Connect Wallet
                        </span>
                    </a>
                </div>
            </div>
        </nav>
  );
}