import "./App.css";
import Header from "./components/Header/header";
import Home from "./pages/Home";
import Contract from "./pages/Contract";
import Footer from "./components/Footer/footer";
import Updates from "./pages/Updates";
import HbtStaking from "./pages/HbtStaking";
import Swap from "./pages/Swap";
import Roadmap from "./pages/Roadmap";
import HedgeBotToken from "./pages/HedgeBotToken";
import Owner from "./pages/Owner";
import Shop from "./pages/Shop";
import TradingBot from "./pages/TradingBot";
import ReserveNow from "./pages/ReserveNow";

function App() {
  const navHome = () => {
    if (window.location.pathname === "/") {
      return <Home />
    }
  }
  const navContract = () => {
    if (window.location.pathname === "/contract") {
      return <Contract />
    }
  }
  const navUpdates = () => {
    if (window.location.pathname === "/updates") {
      return <Updates />
    }
  }
  const navShop = () => {
    if (window.location.pathname === "/shop") {
      return <Shop />
    }
  }
  const navHBT = () => {
    if (window.location.pathname === "/hbt-staking") {
      return <HbtStaking />
    }
  }
  const navRoadMap = () => {
    if (window.location.pathname === "/roadmap") {
      return <Roadmap />
    }
  }
  const navSwap = () => {
    if (window.location.pathname === "/swap") {
      return <Swap />
    }
  }
  
  const navToken = () => {
    if(window.location.pathname === "/hedgebot-tokens"){
      return <HedgeBotToken />
    }
  }
  const navAdmin = () => {
    if(window.location.pathname === "/my-admin"){
      return <Owner />
    }
  }
  const navTradingBot = () => {
    if(window.location.pathname === "/trading-bot"){
      return <TradingBot />
    }
  }
  const navReserveNow = () => {
    if(window.location.pathname === "/reserve-now"){
      return <ReserveNow />
    }
  }
  if((window.location.pathname !== "/trading-bot") && (window.location.pathname !== "/reserve-now")){
      return (
        <>
        <div className="App">
          <Header />
          {navHome()}
          {navContract()}
          {navUpdates()}
          {navHBT()}
          {navRoadMap()}
          {navSwap()}
          {navToken()}
          {navAdmin()}
          {navShop()}
          <Footer />
        </div>
        </>
      );
  }else{
      return (
        <>
        <div className="App">
          {navTradingBot()}
          {navReserveNow()}
          <Footer />
        </div>
          
        </>
      );
  }
}

export default App;
//npm install --save react-scripts@4.0.3