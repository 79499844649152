import React from "react";
export default function Shop(props) {
  return (
    <>
        <div class="text-center bg-swag py-3 mt-5 font-weight-normal ">
            <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-12">
                        <div class="display-2 text-black">OUR SWAG IS COMING APRIL 2023</div>
                    </div>
                </div>
            </div>
        </div>
        <section id="faq" class="bg-black">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-lg-6 order-lg-1 mb-5 mb-lg-0">
                        <div class="text-center py-5 px-4 mt-5">
                            <div class="color-swag h2 mb-5 pb-3 font-weight-900">Sacrifice 10 USDC Or More To Enter in Our SWAG Giveaway</div>
                            <button class="btn btn-theme text-center px-4 py-2 ms-sm-3 mb-lg-0">
                                <span class="d-block text-white font-weight-bold">
                                    SWAG SHOP
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 order-lg-1 mb-5 mb-lg-0">
                        <div class="card">
                            {/* <!-- card left --> */}
                            <div class="product-imgs">
                              <div class="img-display">
                                <div class="img-showcase">
                                  <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42452-PM.webp" alt="" />
                                  <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42506-PM.webp" alt="" />
                                  <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42516-PM.webp" alt="" />
                                  <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42529-PM.webp" alt="" />
                                  <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42540-PM.webp" alt="" />
                                </div>
                                <div class="nav-slider d-flex align-items-center justify-content-between">
                                    <div class="prev h1">&lt;</div>
                                    <div class="next h1">&gt;</div>
                                    <input type="hidden" id="slide-current" value="1" />
                                </div>
                              </div>
                              <div class="img-select">
                                <div class="img-item">
                                  <a href="#" rel="noreferrer"  data-id="1" class="selected">
                                    <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42452-PM.webp" alt=""  />
                                  </a>
                                </div>
                                <div class="img-item">
                                  <a href="#" rel="noreferrer"  data-id="2">
                                    <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42506-PM.webp" alt=""  />
                                  </a>
                                </div>
                                <div class="img-item">
                                  <a href="#" rel="noreferrer"  data-id="3">
                                    <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42516-PM.webp" alt=""  />
                                  </a>
                                </div>
                                <div class="img-item">
                                  <a href="#" rel="noreferrer"  data-id="4">
                                    <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42529-PM.webp" alt=""  />
                                  </a>
                                </div>
                                <div class="img-item">
                                    <a href="#" rel="noreferrer"  data-id="5">
                                      <img src="assets/img/product-images/Screen-Shot-2022-12-27-at-42540-PM.webp" alt=""  />
                                    </a>
                                  </div>
                              </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
  );
}