import React from "react";
export default function TradingBot(props) {
  return (
    <>
      
      <section class="p-0 parallax-slide parallax1" style={{background:'linear-gradient(0deg, rgb(6 6 6 / 70%), rgb(1 1 1 / 39%)) , url(assets/img/bg-trading-bot.webp)' }}>
        <div class="text-center bg-parrot py-2 font-weight-normal ">
            <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                        <h1 class="text-white font-weight-800">Get 50% OFF</h1>
                        <h5 class="text-white font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only 500 Subscribers available!&nbsp;&nbsp;&nbsp;</h5>
                        <a href="/reserve-now" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn bg-white rounded-1 text-black px-lg-4 py-2 mb-lg-0 font-weight-700 btn-reserve">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reserve Now&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row py-5">
          <div class="col-lg-8 offset-lg-2 py-lg-5">
            <div class="row py-lg-5">
              <div class="col-lg-4"><img src="assets/img/hedgebot-transparent-logo.webp" alt="" /></div>
              <div class="col-lg-8">
                <h1 class="font-weight-900 font-size-104">HedgeBot</h1>
                <h1 class="fs-1"><span class="font-weight-normal">AUTOMATED</span>&nbsp;<span class="font-weight-700">TRADING BOT</span></h1>
                <h5><i>Get amazing returns with AI trading cryptocurrencies</i></h5>
                <a href="/reserve-now" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn btn-reserve2 rounded-pill text-white px-lg-5 py-2 lh-base mb-lg-0 font-weight-700 fs-1875rem my-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Reservation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
              </div>
            </div>
          </div>
        </div>
      </section> 
      <section class="p-0">
          <div class="text-center bg-white  font-weight-normal ">
              <div class="container px-5">
                  <div class="row gx-5 justify-content-center">
                      <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                         <img src="/assets/img/binance.webp" alt="Binance"  class="mx-2" />
                         <img src="/assets/img/coinbase.webp" alt="Coinbase Pro" class="mx-2 mb-2"   />
                         <img src="/assets/img/bitstamp.webp" alt="BitStamp" class="mx-2"  />
                         <img src="/assets/img/kucoin.webp" alt="Kucoin" class="mx-3"  />
                         <img src="/assets/img/kraken.webp" alt="Kraken" class="mx-2"  />
                         <img src="/assets/img/poloniex.webp" alt="Poloniex" class="mx-2"  />
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="p-0 bg-swag pb-5">
          <div class="text-center font-weight-normal ">
              <div class="container px-5 py-5">
                  <div class="row gx-5 py-5 justify-content-center">
                      <div class="col-lg-6 px-5 position-relative">
                          <img src="assets/img/passive-income.webp" class="rounded-10 img-fluid" alt="Passive Income" />
                      </div>
                      <div class="col-lg-6 text-start mt-5">
                        <h1 class="font-weight-700">Generate Passive Income using HedgeBot</h1>
                        <h5 class="font-weight-normal py-4">Use our automated trading bots to generate passive income. Backtested for 12 months with an average 70% winning  rate.</h5>
                        <ul>
                          <li class="h5 font-weight-700 lh-base">Average 23.5% Monthly Profit (282% ARP)</li>
                          <li class="h5 font-weight-700 lh-base">Longing and shorting Ethereum (OG-33)</li>
                          <li class="h5 font-weight-700 lh-base">Set it and forget it risk parameters</li>
                          <li class="h5 font-weight-700 lh-base">Trading Open 7 days a week</li>
                          <li class="h5 font-weight-700 lh-base">You have full control of your funds</li>
                        </ul>
                        <a href="/reserve-now" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn btn-reserve3 rounded-pill text-white px-lg-5 py-2 lh-base mb-lg-0 font-weight-700 fs-1875rem my-5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Reservation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="p-0 bg-black pt-5">
          <div class="text-center font-weight-normal ">
              <div class="container px-5 py-5">
                  <div class="row gx-5 py-5 justify-content-center">
                      <div class="col-lg-12 text-center mt-4">
                        <h1 class="font-weight-700">Checkout Our 2022 Backtesting Results</h1>
                        <div class="row gx-5 py-5 my-4">
                              <div class="align-bottom col-lg-4 d-flex flex-column justify-content-end p-0">
                                <div class="bg-passive px-5 py-4 text-start ms-auto rounded-3">
                                  <p class="mb-1 font-weight-bold">Starting capital: $1,000</p>
                                  <p class="mb-1 font-weight-bold">Net Annual Profit: $11, 154.63</p>
                                  <p class="mb-1 font-weight-bold">Max Drawdown: 18.39%</p>
                                  <p class="mb-1 font-weight-bold">Average Trade: 1.78% Profit</p>
                                </div>
                              </div>
                              <div class="col-lg-8 p-0" id="slider">
                                <span  class="control_next"><img src="assets/img/right-arrow.png" alt="" class="img-fluid" /></span>
                                <span class="control_prev"><img src="assets/img/left-arrow.png" alt="" class="img-fluid" /></span>
                                <ul class="list-unstyled">
                                  <li><img src="assets/img/slide1.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide2.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide3.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide4.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide5.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide6.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide7.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide8.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide9.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide10.png" class="img-fluid" alt="" /></li>
                                  <li><img src="assets/img/slide11.png" class="img-fluid" alt="" /></li>
                                </ul> 
                              </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-1"></div>
                          <div class="col-lg-10">
                            <p class="small lh-base font-weight-normal">All data provided are purely historical. The future results may not reflect the past. We provide historical data so you can make a more informed decision. Remember that trading involves risk, so do your own research before making any investing or trading decisions.</p>
                            <a href="/reserve-now" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn btn-reserve2 rounded-pill text-white px-lg-5 py-2 lh-base mb-lg-0 font-weight-700 fs-1875rem my-5">Free Reservation</button></a>
                          </div>
                          <div class="col-lg-1"></div>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="container px-5 pt-5">
                  <div class="row gx-5 pt-4 justify-content-center">
                      <div class="col-lg-12 text-center">
                        <h1 class="font-weight-800 display-2">Up to 5x Leverage!</h1>
                        <div class="row mt-5 pt-5">
                          <div class="col-lg-7">
                            <img src="assets/img/leverage.webp" class="img-fluid" alt="" />
                          </div>
                          <div class="col-lg-5 text-start  mt-3 pe-5 pt-5">
                            <h1 class="fs-9 font-weight-800">Small investment, big impact: unleash the power of leverage!</h1>
                            <h5 class="font-weight-normal lh-base pt-4">Leverage can provide traders with the opportunity to increase their returns on investment by magnify their positions. When used correctly, leverage can help traders achieve their financial goals more quickly and efficiently than traditional investing</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-10 offset-lg-1 pt-lg-5 px-lg-0">
                        <p class="small lh-base font-weight-normal text-start">While leverage can amplify profits, it can also amplify losses, potentially leading to significant financial damage. Traders who use leverage need to be cautious and understand the risks involved, as a small market movement can result in large losses that can exceed the initial investment. It is crucial to have a well-thought-out trading plan, a disciplined approach, and risk management strategies in place to mitigate the potential dangers of using leverage.</p>
                      </div>
                      <div class="col-lg-12 py-lg-5">
                        <h4 class="font-weight-normal fs-1875rem lh-base">Get Full Access to Automated Trading Bot</h4>
                        <h4  class="font-weight-normal fs-1875rem  lh-base">for only</h4>
                        <h1 class="pt-lg-4 font-weight-800 color-red"><strike>$97 USD / Month</strike></h1>
                        <h1 class="font-weight-800 display-4 color-green">$49 USD / Month</h1>
                        <a href="/reserve-now" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn btn-reserve2 rounded-pill text-white px-lg-5 py-2 lh-base mb-lg-0 font-weight-700 fs-1875rem my-4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Reservation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                        <p class="text-center font-small py-lg-4">Limited Time Offer. Only 500 Subscribers Available</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <div style={{background:'url(assets/img/limited-offer-bg.webp)',backgroundPosition:'left bottom',backgroundSize:'cover',height:"300px",opacity:"0.8"}}></div>
      <section class="p-0">
          <div class="text-center bg-white  font-weight-normal ">
              <div class="container px-5 py-3">
                  <div class="row gx-5 justify-content-center">
                      <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                         <img src="/assets/img/coinex.webp" alt="Coinex"  class="mx-3" />
                         <img src="/assets/img/bittrex.webp" alt="Bittrex" class="mx-3"   />
                         <img src="/assets/img/bybit.webp" alt="Bybit" class="mx-2"  />
                         <img src="/assets/img/binance-us.webp" alt="Binance.us" class="mx-3"  />
                         <img src="/assets/img/okex.webp" alt="Okex" class="mx-2"  />
                         <img src="/assets/img/huobi.webp" alt="Huobi" class="mx-2"  />
                      </div>
                  </div>
              </div>
          </div>
          <div class="text-center bg-parrot py-2 font-weight-normal ">
              <div class="container px-5">
                  <div class="row gx-5 justify-content-center">
                      <div class="col-xl-12 d-flex flex-wrap align-items-center justify-content-center">
                          <h1 class="text-white font-weight-800">Get 50% OFF</h1>
                          <h5 class="text-white font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only 500 Subscribers available!&nbsp;&nbsp;&nbsp;</h5>
                          <a href="/reserve-now" class="flex align-items-center flex-column justify-content-center text-decoration-none mx-3"><button class="btn bg-white rounded-1 text-black px-lg-4 py-2 mb-lg-0 font-weight-700 btn-reserve">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reserve Now&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button></a>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="p-0 bg-black pt-5">
          <div class="text-center font-weight-normal ">
              <div class="container px-5 py-5">
                <div class="row gx-5 justify-content-center">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-8 p-lg-0">
                      <h1 class="font-3rem font-weight-800 mb-5">FAQs</h1>
                      <p class="font-weight-700 h5 text-start pt-5">What is the difference between the automated “HedgeBot Trading Bot” and the “HedgeBot Trading Pool”?</p>
                      <p class="text-start mt-3 mb-4">When you decide to use our Trading Bot, you connect the trading exchange of your choice with our trading terminal. Your funds stay in your account at all times. You can withdraw them at any time.</p>
                      <p class="text-start mb-5">When you participate in HedgeBot’s Trading Pool, your funds are traded and managed by our expert traders and fund managers. You are charged a 1% fee for both deposits and withdrawals. Your initial capital investment is locked for 180 days. After that time, you can request a refund of your capital. Keep in mind that a 25% fee will be applied to your refund.</p>

                      <p class="font-weight-700 h5 text-start">If I cancel my current subscription can I subscribe again at the same price?</p>
                      <p class="text-start mt-3 mb-5">Yes, you will be able to join again at the price listed on the website when you decide to rejoin. Remember that subscriptions are all subject to availability. If subscriptions are closed, you can still join the waitlist.</p>
                      
                      <p class="font-weight-700 h5 text-start">What other automated trading bots will HedgeBot release?</p>
                      <p class="text-start mt-3 mb-5">We are currently only taking reservations for our Ethereum Trading Bot “OG-33”. If the community demand exceeds the initial 500 reservations, we will release our MATIC Trading Bot “OG-43” and potentially a Bitcoin Trading Bot “OG-53” to accommodate the demand.</p>
                      
                      <p class="font-weight-700 h5 text-start">Do I have to be a HedgeBot member to use the automated trading bot?</p>
                      <p class="text-start mt-3 mb-5">Yes, to access the trading bot you must buy and stake 10 HBT tokens to access our trading terminal and trading bots provided by HedgeBot.</p>
                      
                      <p class="font-weight-700 h5 text-start">How do I reserve my spot as one of the first 500 subscribers?</p>
                      <p class="text-start mt-3 mb-4">Simply provide your name and email address. You are not required to pay anything to join the waitlist. 72 hours before the public release of our Ethereum trading bot (OG-33), you will receive an email to complete your account setup. </p>
                      <p class="text-start mb-5">Only the first 500 who upgrade will access to the Ethereum trading bot (OG-33).</p> 

                      <p class="font-weight-700 h5 text-start">Got more questions? Looking for support?</p>
                      <p class="text-start mt-3 mb-5">We suggest you visit our knowledge base for more information about the HedgeBot ecosystem.</p>

                  </div>
                  <div class="col-lg-2"></div>
                </div>
              </div>
          </div>
      </section>
    </>
  );
}